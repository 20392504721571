@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {font-family: 'Poppins';font-weight: 400;font-size: 14px;line-height: 24px;-webkit-font-smoothing: antialiased;padding: 0;margin: 0;color: var(--body_text_color); background-color: var(--bodybg);}
a{text-decoration: none; color: var(--primary); cursor: pointer;;}
a:hover{color: var(--secondary);}
li{list-style: none;}
ul{padding: 0; margin: 0;}
:focus-visible {outline: none;}
.btn{padding: 8px 20px; border: 1px solid var(--colordark); transition: 0.3s;}
.btn.active{background-color: var(--secondary); border-color: var(--secondary); color: var(--colorwhite);}
.btn-primary{background-color: var(--primary); border-color: var(--primary);}
.btn-primary:hover{background-color: #ff7235;border-color: #ff7235;}  
.btn-secondry{background-color: var(--secondary);; border-color: var(--secondary);; color:#fff;}
.btn-secondry:hover{ background-color:#1e85b3;  border-color:#1e85b3; color: #fff;}
.btn-default{background-color: transparent; border-color: var(--colordark); color: var(--colordark);}
.btn-default:hover{ background-color: var(--secondary);  border-color: var(--secondary); color: #fff;}
.btn-danger {border-color: #dc3545;}
.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
  color: var(--bs-btn-active-color);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
 color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  opacity: var(--bs-btn-disabled-opacity);
}
.worldwidephone.locationinput .PhoneInputInput{border-left: none;    color: var(--other_text_color);}
.pac-container{border: none; background-color: var(--card_hover_bg);}
.pac-item,.pac-item-query{color: var(--other_text_color); border-color: var(--card_border_color);padding: 7px 5px;}
.pac-item:hover{background-color: var(--card_bg);}

.pac-item-selected, .pac-item-selected:hover {
  background-color: var(--card_bg);
}
/* globalStyles.css */
 
 

[data-theme="dark"] input:-webkit-autofill,[data-theme="dark"]
input:-webkit-autofill:hover,[data-theme="dark"]
input:-webkit-autofill:focus,[data-theme="dark"]
input:-webkit-autofill:active  { -webkit-box-shadow: 0 0 0 30px var(--dashboard_banner_card_border) inset !important; /* Change 'white' to your desired background color */
  -webkit-text-fill-color: #fff !important; /* Change this to your desired text color */}

.form-group .MuiButton-root{background-color: var(--primary);}
.form-group .MuiButton-root:hover{background-color: #ff7235;border-color: #ff7235;}
.form-group .MuiLoadingButton-root.Mui-disabled{    background-color: #ffa680;color: #000;}
.bg-primary{background-color: var(--primary) !important;}
.text-primary{color: var(--primary) !important;} 
.bg-secondry{background-color: var(--secondary) !important;}
.text-secondry{color: var(--secondary) !important;}
.bg-success{    background-color: #26af48 !important;}
.text-success{color: #26af48 !important;}
.bg-bluelight{    background-color: #fcfaff;}
.bggray {background-color: #fffafa;}
h1, h2, h3, h4, h5 {font-family: 'Poppins';}
h1,h2,h3,h4,h5{margin-bottom: 0;}
h1,h2,h3,h4,h5,h6{color: var(--heading_color);}
 
 
    
:root {
  --primary:#f16122;
  --secondary:#439bc2;
  --bodybg:#fff;
  --homebannerbg:#fff4f4;
  --body_text_color:#212B36;
  --other_text_color:#212B36; 
  --heading_color:#212B36;
  --card_bg:#fafafa;
  --card_hover_bg:#eee;
  --card_border_opacity: 1;
  --card_border_color:#d8d8d8;
  --card_boxshadow:rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px; 
  --sidebarbg:#fff; 
  --sidebarbg_bg: #fafafa;
  --text_muted:#637381; 
  --colordark:#212B36; 
  --footerbg:#fafafa;
  --bottomfooterbg:#eee;
--report_card_content:#212B36;
--repomainbg:#f3f5f8;
--loginbg:#f3f5f8;
--personalize_top_header:#fff4f4;
--inputbg:#fff;
--dashboard_banner_card:#fff;
--dashboard_banner_card_border:#e5e5e5;
--scroll_track:#f1f1f1;
--scroll_thumb:#d3d3d3;
  }     

    
      [data-theme="dark"]  {
        --primary:#f16122;
        --secondary:#439bc2;
        --bodybg:#141314;
        --body_text_color:#e6e1e3;
        --other_text_color:#e6e1e3; 
        --heading_color:rgb(255, 255, 255);
        --text_muted:#DFE3E8; 
        --colordark:#212B36; 
        --card_bg:#1c1b1d;
        --card_hover_bg:#45455a; 
        --card_border_opacity: 1;
        --card_border_color:rgb(51 49 51 / var(--card_border_opacity));
        --card_boxshadow:rgba(0, 0, 0, 0.4) -40px 40px 80px; 
        --sidebarbg:#211f21; 
        --sidebarbg_bg: #2e2c2e;
        --card_header_bg:#211f21; 
        --bottomfooterbg:#211f21;
         --homebannerbg:#141314; 
        
     
       
        --report_card_content:#e6e1e3;
        --repomainbg:#161c24;
        --loginbg:#000; 
        --personalize_top_header:#20252e;
        --inputbg:#211f21;
        --dashboard_banner_card:#1e1e1e;
        --dashboard_banner_card_border:#444;
        --scroll_track:#fff;
        --scroll_thumb:#ff7955;
    }      
        




body::-webkit-scrollbar{width: 10px; height: 5px;background-color: transparent;}
body::-webkit-scrollbar-track{  background-color: #fff;}
body::-webkit-scrollbar-thumb{  background-color: #c1c1c1;}

 ::-webkit-scrollbar{width: 5px; height: 5px; background-color: transparent;}
 ::-webkit-scrollbar-track{ border-radius: 10px;background-color: var(--scroll_track);}
 ::-webkit-scrollbar-thumb{border-radius: 10px; background-color: var(--scroll_thumb);}
 
.text-muted{color: var(--text_muted) !important;}
.badge {font-size: 12px;letter-spacing: 1px;font-weight: 500;}
.bg-inverse-success {background-color:rgba(15, 183, 107,0.12) !important;color: #26af48 !important;} 
.bg-inverse-danger {background-color: rgba(242, 17, 54,0.12) !important;color: #e63c3c !important;} 
.bg-inverse-purple {background: rgba(108, 97, 246, .2) !important;color: #6c61f6 !important;} 
.bg-inverse-white {background: #fff !important;color: #484f68 !important;}
.bg-inverse-blue {background-color: rgb(117, 197, 255, .2) !important;color: #3a90cf !important;}
.bg-inverse-info {background-color: rgba(2, 182, 179, 0.12) !important;color: #1db9aa !important;}
.bg-inverse-gray {background: rgb(181 181 181 / 20%) !important;color: #ababab !important;}
.bg-inverse-int {background: rgb(54 112 188 / 20%) !important; color: #406095 !important;}
.form-group {margin-bottom: 1rem;position: relative;}
.form-group label {display: block;margin-bottom: 5px;font-weight: 500;font-size: 14px; font-size: 13px; color: var(--other_text_color);}
.form-group input {font-size: 14px !important;     background:var(--inputbg);}
.form-group svg{color: var(--other_text_color);}
.MuiInputBase-root{ background:var(--inputbg) !important;}
input {font-size: 14px !important; }
.form-check-input{      width: 20px !important;
  height: 20px !important;
  margin-right: 12px !important;  background-color:var(--inputbg) !important;
  border: 1px solid var(--card_border_color) !important;
  box-shadow: var(--card_boxshadow) !important;}
  .form-check-input:checked{    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;}
 
textarea {font-size: 14px !important;     background: var(--inputbg) !important;}
.MuiTextField-root {width: 100%;font-size: 13px;}
.MuiMenuItem-root , .MuiAutocomplete-option{font-size: 13px !important;}
.MuiSelect-select {font-size: 14px !important; background: var(--inputbg) !important;}
.MuiRadio-root.Mui-checked  {color: var(--secondary) !important;} 
.MuiInputLabel-root {font-size: 14px !important;font-weight: 500 !important; color: var(--other_text_color) !important; }
.MuiTypography-root{font-size: 14px !important;}
.MuiRadio-root{color: var(--body_text_color) !important;}
fieldset{border-color: var(--card_border_color) !important;    border-width: 1px !important;}
.MuiInputBase-root{color: var(--other_text_color) !important;}
.container {max-width: 1350px;}
.page-loader {padding: 200px 0px;background: var(--bodybg);width: 100%;height: 100%;z-index: 99;left: 0;right: 0;margin: 0 auto;display: flex;
align-items: center;justify-content: center;}
.inner-loader {align-items: center;display: flex;flex-direction: column;gap: 15px;text-align: center;font-size: 12px;font-size: 14px;}
.inner-loader span {display: block;margin-top: 10px;font-size: 16px;}


 /*header css start*/
.mini-header {background: #fff;height: 50px;display: none;}
header{padding:15px 15px; background-color: transparent;    position: absolute; top: 0; z-index: 99; width: 100%;  }
header.fixed-header{  background-color: var(--bodybg);      position: fixed;}
.inner-header header {position: inherit;     border-bottom: 1px solid  var(--card_border_color);}
.inner-header header.fixed-header{position: fixed;}
.nav-flex {display: flex;align-items: center;}
 
.logo {width: 200px;}  
.logo img {width: 100%;} 
.navbar-menu {flex-grow: 1;}
.navbar-menu ul {height: 100%;width: 100%;display: flex;align-items: center;  justify-content: center;}
.navbar-menu ul li{margin: 0px 10px;    position: relative; transition: 0.3s;}
 
 .navbar-menu ul li a{color:var(--other_text_color);   padding-bottom: 7px;   position: relative; cursor: pointer; font-size: 16px;}
 
 
.navbar-menu ul li .main-menu::after {content: '';display: block;width: 0;height: 2px;background: var(--secondary);transition: width 0.2s;    position: absolute;bottom: 0;}
.navbar-menu ul li .main-menu:hover::after , .navbar-menu ul li .main-menu.active::after {width: 100%;} 
.submenu {display: none;}
.navbar-menu ul li:hover .submenu ,.navbar-menu ul li:hover .dropicon{display: block;}
.dropicon{ display: block;position: absolute;width: 10px;height: 10px;background-color: #fff;-webkit-transform: translateY(-50%) rotate(45deg);-moz-transform: translateY(-50%) rotate(45deg);-ms-transform: translateY(-50%) rotate(45deg);transform: translateY(-50%) rotate(45deg);z-index: 0;left:0;right: 0; top:66px;box-shadow: 0 4px 20px rgba(13,36,69,.2);margin: 0 auto; display: none; }
.submenu-content  {position: absolute;background-color: #fff;    box-shadow: 0 12px 20px rgba(13,36,69,.2);border-radius: 8px;padding: 38px 26px 32px;top: calc(100% + -9px);left: -100%;display: grid;grid-column-gap: 30px;  -moz-column-gap: 30px;column-gap: 30px;grid-template-columns: 1fr 1fr; z-index: 99;}

.submenu-item{margin-bottom: 10px;    min-width: 330px;max-width: 400px;}
.submenu-link h5 {font-size: 14px;}
.submenu-link span {font-size: 12px;} 
.submenu-product {overflow: hidden;line-height: 20px;box-sizing: border-box;border-radius: 8px;width: 100%;transition: background-color .25s ease-in-out;padding: 10px 15px 10px;display: flex;align-items: center;gap: 15px;}
.submenu-product:hover{ background: #fafafe;}
.submenu-product .menu-icon{background-color: #fafafe;  }
.submenu-product .menu-icon svg{color: #161C24;}
.navbar-button{margin-left: auto; display: flex; align-items: center; gap: 10px;} 
.navbar-button a{color: var(--colordark);    }
.navbar-button .btn{padding: 4px 15px;     }
.menubar, .closemenubar{display:none;}


.theam_toggal .MuiTypography-root{    margin-left: 7px;}
 /*header css end*/
 /*table css start*/ 
.not-fount-table{text-align: center;}
.not-fount-center { padding: 70px 0px ;}
.no-data-info__title {margin-bottom: 0px;}
.bigimage img{width: 400px;}
.not-fount-table .btn{font-size: 12px; width: 200px;}
.fond-icon-content{display: flex; flex-direction: column; gap: 10px;}
.found-image img{filter: invert(50%) sepia(91%) saturate(7068%) hue-rotate(214deg) brightness(105%) contrast(98%);}
.tfoot-area {text-align: center;padding: 15px;}  
.web-table thead tr th {    font-weight: 500;font-family: 'Poppins'; color: var(--colordark);}
.web-table tbody tr td{color: var(--other_text_color); border-color: var(--card_border_color);}
.web-table thead tr th,  .web-table tbody tr td{text-align: left;overflow: hidden;  } 
.table-pagination p{margin-bottom: 0;}
.table-pagination {border-top: 1px solid #eee;} 
.file-row{display: flex; align-items: center; gap: 5px; }
.file-row svg{    color: var(--color500);}
.user-profile {position: relative;white-space: nowrap;}
.user-profile-head a {display: flex;  align-items: center; color: #000;  line-height: 20px;  gap: 7px;}
.user-profile-pick {display: flex;align-items: center;}
.user-profile-pick i {font-size: 28px;}
.user-profile-content {margin-left: 5px;  } 
.inner-header  .user-profile-content { color: var(--colordark);} 
.fixed-header .user-profile-content {color: var(--colordark);}
.user-profile-content span { display: block;font-size: 12px;     
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  }
.user-profile-content .user-name {font-weight: 600;font-size: 13px;}
.user-profile-pick .MuiAvatar-circular {background-color: var(--primary);color: #fff;font-size: 13px;width: 32px;height: 32px; text-transform: capitalize;}
.filerowname {overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100%;max-width: 200px;display: inline-block;vertical-align: middle;}
.pdflistingtable {
  background: #f9efea;
  border-radius: 15px;
}


.card_table .web-table thead tr th, .card_table .web-table tbody tr td{padding: 8px 15px; }
  .card_table .web-table tbody tr td .badge{padding: 10px 10px; font-size: 0.875rem;}
.card_table .web-table thead tr,.card_table .web-table tbody tr{    border-color: #ebdfd9 !important;}
/*table css end*/
@keyframes slideUp {from {opacity: 0;transform: translateY(100%);}
to {opacity: 1;transform: translateY(0);}}
.animated-text {display: inline-block;animation: slideUp 0.5s ease-in-out forwards;}


 /*banner css start*/
.banner {width: 100%;  overflow: hidden;  background-color: var(--homebannerbg);  border-bottom: 1px solid var(--card_border_color);  }
.banner-content-box {display: flex;align-items: center;}
.banner-content {padding:50px 70px 0px 0px;width:65%;}
.banner-content h1{ font-size: 48px;font-weight: 600;line-height:65px;margin-bottom: 25px;  }
.banner-content p {font-size: 18px;line-height: 34px; color: var(--other_text_color);  }
.text-blue{color: var(--primary);} 
.banner-content .banner-button{margin-top: 30px;}
.banner-image{padding: 140px 0px 50px 0px;  width: 55%;} 
.banner-image img{width: 100%;    -webkit-animation-name: spin;-webkit-animation-duration: 20000ms;-webkit-animation-iteration-count: infinite;-webkit-animation-timing-function: linear;-moz-animation-name: spin;-moz-animation-duration: 20000ms;-moz-animation-iteration-count: infinite;
-moz-animation-timing-function: linear;-ms-animation-name: spin;-ms-animation-duration: 20000ms;-ms-animation-iteration-count: infinite;-ms-animation-timing-function: linear;animation-name: spin;animation-duration: 20000ms;animation-iteration-count: infinite;animation-timing-function: linear; }
@-moz-keyframes spin {from { -moz-transform: rotate(0deg); }to { -moz-transform: rotate(360deg); }}
@-webkit-keyframes spin {from { -webkit-transform: rotate(0deg); }to { -webkit-transform: rotate(360deg); }}
@keyframes spin {from {transform:rotate(0deg);}to {transform:rotate(360deg);}}
 /*banner css start*/
/*creeate assesment section css start*/
.section-pedding{padding: 100px 0px;}
.padding_medium{padding: 50px 0px;}
.border-verticle{width: 1px ; height: 2rem;  display: flex; margin: 0 auto;background-image: linear-gradient(to top,var(--tw-gradient-stops));}
.borderbg {--tw-gradient-from: #434895 var(--tw-gradient-from-position);--tw-gradient-from-position: ;--tw-gradient-to: rgba(79,70,229,0) var(--tw-gradient-from-position);--tw-gradient-to-position: ;--tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);} 
.circle-dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px;
  display: flex;
  border: 1px solid;
  background-color: #fff;
}
.border-dashed {
  display: flex;
  border: 1px dashed rgba(82, 82, 91, 1);
  border-width: 0.2px;
  align-self: center;
  flex: 1 1 75%;
  height: 100%;
  width: 1px;
  margin: 0 auto;
}
.step-heading h2{font-weight: 700;}
.step-heading p {margin-top: 15px !important;line-height: 28px; font-size: 16px;} 
.margbottom-50{margin-bottom:50px;}
.paddinglr {padding: 0px 100px;}
/*creeate assesment section css end*/
  
/*category page css start*/
.astro_category_flex{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; gap:15px;}
.astro-category_cta { 
  background-color: var(--card_bg); border: 1px solid var(--card_border_color);
  box-shadow: var(--card_boxshadow);
  border-radius: 15px;position: relative;overflow: hidden;}
  .astro-category_cta:hover {
    background: var(--card_hover_bg);
}
.astro-category_cta.importantcta {
  background: var(--primary);
  box-shadow: none;
}
.astro-category_cta.importantcta .category_icon{background-color: #fff;}
.astro-category_cta:after {content: "";position: absolute;
  /* background: var(--secondary); */
  bottom: 0;height: 4px;width: 100%;transition: all ease .4s;}
.love:after{background: linear-gradient(135deg, #fc7959, #f96666);}
 
.finance:after{background: linear-gradient(135deg, #79ff72, #5eae59);}
.family:after{background: linear-gradient(135deg, #f3e1a8, #ffc008);}
/* .career:after{  background: linear-gradient(135deg, #00c0f1, #0095ff); } */
.astro-category_cta a {position: relative;z-index: 2;}
.astro-category_cta:hover:after {height: 100%;}
.astro-category_cta:hover .category_content h3 {color: var(--other_text_color);}
.category_icon {width: 150px;height: 150px;margin: 35px auto 0px  auto;border-radius: 50%;position: relative;padding: 35px;display: flex;align-items: center;justify-content: center;background-color:var(--card_bg); border: 1px solid var(--card_border_color);}
.category_icon img {width: 100%;}
.category_content {text-align: center;padding: 25px 0px 30px 0px;}
.category_content h3{font-size: 18px;}
/*category  section css end*/

/*feature page css start*/

.our_features_box {
  background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  box-shadow: var(--card_boxshadow);
  border-radius: 15px;
  padding: 50px;
  margin-bottom: 30px;
}
.our_features_flex {
  display: flex;
  align-items: center;
}

.feature_content{width: 70%; flex-grow: 1;}

.feature_content h2{font-weight: 600; margin-bottom: 15px;  color: var(--primary);   }

  [data-theme="dark"] .feature_content h2{background: linear-gradient(to right, #f56b30 25%, #def5ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;}
.feature_content p{font-size: 16px; line-height:28px; color: var(--other_text_color);}
.feature_button{margin-top: 30px;}
.feature_button .btn{width: 50%;}

.feature_image{    width: 400px;
  flex-shrink: 0;
  height: 400px; position: relative;}
  .feature_image_box {
    width: 400px;
    flex-shrink: 0;
    height: 360px;
}
.feature_image_box img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
}
.imagetight_textleft{flex-direction: row-reverse;}
.textleft_imagetight .feature_content {padding-right: 50px;}
.imagetight_textleft .feature_content{padding-left: 50px;}
.relationshiptool_home{height: 500px !important;}
/*feature page css end*/
/*testimonial section  css start*/
.swiper{padding-bottom: 70px !important;}
.testimonial_card {text-align: center;background-color: var(--card_bg);border: 1px solid var(--card_border_color);margin: 15px 0px;padding: 30px 15px; border-radius: 15px;}
.testimonial_img{width: 120px !important; height: 120px !important; margin: 0 auto;  border: 1px solid var(--primary); padding: 5px;}
.testo_name{margin-top: 10px; font-weight: 600;  color: var(--heading_color); }
.testo_message{margin-top: 15px;    position: relative;}
blockquote {position: relative;font-family: "Georgia", serif; quotes: "\201C" "\201D" "\2018" "\2019";}
blockquote::before, blockquote::after {color: #c6c6c6;font-size: 4em;font-weight: 400;line-height: 0.1em;}
blockquote::before {position: absolute;left: 0px;content: open-quote;margin-right: 0.15em;vertical-align: -0.15em;}
blockquote::after {content: close-quote;vertical-align: -0.7em;right: 0px;}
blockquote span{ font-family: 'Poppins';}
.swiper-pagination-bullet {background-color: #978271;width: 16px;height: 16px;}
.swiper-pagination-bullet-active{background-color: var(--secondary);}
/*testimonial section  css start*/

/*Horoscope section css start*/
.everything{ overflow: hidden;}
.everything .dot-overlay {position: absolute;left: 0px;top: 0;width: calc(100% - 0px);height: 100%;display: flex; }
.everything .dot-overlay>span {flex: 1;
  /* border-left: 1px dashed var(--card_border_color); */

}
.everything  .container {position: relative;}
.technology-listing::before, .technology-listing::after {
  content: "";position: absolute;width: 100vw;left: 50%;transform: translateX(-50%);
  /* border-top: 1px dashed var(--card_border_color); */
}
.technology-listing::after {top: auto;bottom: 0;}
.technology-cta::before {position: absolute;content: "+";color: var(--color500);font-size: 25px;line-height: 0;left: 0; display: none;}
.technology-cta:nth-child(odd)::before {left: -9px;top: 0px;}
.technology-cta:nth-child(even)::before {top: 0;left: -9px;} 
.technolist{ margin-top: 15px;}
.technology-listing{display: grid;flex-wrap: wrap;position: relative; gap: 15px; grid-template-columns: 1fr   1fr 1fr 1fr 1fr 1fr; justify-content: center; margin-bottom: 15px;}
.technology-cta {text-align: center;margin: 0 auto;flex: 1;padding: 0px 0 0px 0px; position: relative; width: 100%;}
.technology-cta a {    padding: 50px 0px; display: block; transition: 0.3s; 
  background-color: var(--card_bg);
  border: 1px solid var(--card_border_color); 
  box-shadow: var(--card_boxshadow);
  border-radius: 10px;}
 .tech-icon { width: 150px; height: 150px; margin: 0 auto 20px; border-radius: 50%;   position: relative;padding: 35px;display: flex;align-items: center;justify-content: center; background-color: var(--card_bg);border: 1px solid var(--card_border_color); }
.tech-icon img{width: 100%; height: 100%; object-fit: contain;}
 .tech-icon:before {content: "";position: absolute;width: 100%;height: 100%;border: 1px dashed var(--card_border_color);left: 0px;top: 0px;border-radius: 50%;transition: .3s ease-in-out;}
 .tech-icon:hover:before {left: 5px;top: 5px;transition: .3s ease-in-out;}
.technology-cta p{color: var(--other_text_color); margin-bottom: 0; font-weight: 500; font-size: 16px;} 
.technology-cta a:hover { background: var(--card_hover_bg);}
.aries_bg{    background-color: #d7daf4;background-position: 17.7% 83%;}
.taurus_bg{background-color: #f9e2e2;background-position: 5.2% 58.5%;}
.gemin_bg {background-color: #f7dfff;background-position: 51.6% 58.5%;}
.cancer_bg{background-color: #fff3f2;background-position: 34.8% 59%;}
.leo_bg{background-color: #e2f6ff;background-position: 68.5% 59.5%;}
.virgo_bg{  background-color: #fff5d8;}
.libra_bg{  background-color: #fff5d8;}
.scorpio_bg{background-color: #e2f6ff;background-position: 68.5% 59.5%;}
 .sagittarius_bg{background-color: #fff3f2;background-position: 34.8% 59%;}
 .capricorn_bg{background-color: #f7dfff;background-position: 51.6% 58.5%;}
.aquarius_bg{background-color: #f9e2e2;background-position: 5.2% 58.5%;}
.pisces_bg{   background-color: #d7daf4;background-position: 17.7% 83%;} 
/*Horoscope   section css end*/
 

/*blog page css start*/
.blog-menu{padding: 10px 15px;}
.blog-menu a{color: var(--colordark);}
 .blog-flex{display: flex;gap: 15px;padding-bottom: 15px;}
.blog-sidebar{  flex-shrink: 0;  }
.blog-aside {     overflow: auto;border: 1px solid var(--card_border_color);transition: width .25s;border-radius: 5px;     background-color: var(--card_bg); position: sticky;top: 80px;height: calc(100vh - 82px);z-index: 9;width: 265px;}
section{ scroll-margin: 150px;}
.closeblog ,.blog-menu{display: none;}
.pointtext b {font-weight: 500;}
.pointtext {display: flex;gap: 15px;}.pointtext span {flex-shrink: 0;}
.blog_section_pedding{padding: 0px 0px 30px 0px;}
.blog-content{flex-grow: 1;  }
.blog-content h1, .blog-content h2, .blog-content h3 , .blog-content h4, .blog-content h5, .blog-content h6{margin: 20px 0px; font-weight: 600;}
.blog-content h1{font-size: 30px;}
.blog-content h2{font-size: 22px;} 
.blog-content h3{font-size: 20px;  line-height: 30px;  }
.blog-content h4{font-size: 18px;    line-height: 26px; margin-bottom: 5px;}
.blog-content h5{    font-size: 16px;line-height: 26px;margin-bottom: 5px;margin-bottom: 2px;}
 .blog-content img{ margin: 30px 0px;   min-width: 400px;max-width: 800px;border: 1px solid var(--card_border_color); }
.blog-banner{       background: linear-gradient(180deg, rgba(244, 227, 210, 1) 0%, rgba(254, 244, 234, 1) 73%, rgba(255, 255, 255, 1) 100%);  }
.blog-breadcrumbs {margin-top: 15px;}
.blog-breadcrumbs ol li a {color: var(--other_text_color); text-decoration: underline;}
.blog-aside .MuiListItemButton-root{color: var(--other_text_color);}
.syntax-head{margin-bottom: 15px; margin-top: 10px;}
.language-tabs {display: flex;align-items: center;gap: 10px;}
.language-tabs .btn {padding: 3px 10px;font-size: 14px;}
 .copy-code a {background: #f9f9f9;width: 30px;border: 1px solid var(--card_border_color);height: 30px;display: flex;align-items: center;justify-content: center;border-radius: 50%;}
.copy-code a:hover{background-color: #eee;}
.copy-code a svg{font-size: 16px;}
.blog-list{margin-top: 15px;}
.blog-list ul {padding: revert;}
.blog-list ul li{margin-bottom: 15px; list-style: auto;  }
.blog-content p b{color: var(--colordark); font-weight: 600;}

.blog-image {position: relative;box-sizing: border-box;display: block;width: initial;height: initial;background: none;opacity: 1;border: 0px;margin: 0px;padding: 67.3307% 0px 0px;border-radius: 14px;margin-bottom: 18px;overflow: hidden;width: 100%;}
.blog-image img {position: absolute;inset: 0px;box-sizing: border-box;padding: 0px;border: none;margin: auto;display: block;width: 0px;height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;}
.blog-type { margin: 15px 0px;display: flex;align-items: center;justify-content: space-between;}
.update-date{color: var(--other_text_color);}
.type-btn{border: 1px solid var(--secondary); color: var(--other_text_color); padding: 4px 15px; border-radius: 7px;    display: inline-block;}
.blog-title {margin-bottom: 10px;}
.blog-content h1 {font-size: 34px;line-height: 42px;}
.blog-title h4 {font-weight: 600;line-height: 32px; font-size: 22px;}
.blog-block a{color: var(--colordark); }
.blog-pera p{color: var(--other_text_color);}
.blog-block-content {padding-right: 0px;}
.blog-block a:hover .blog-title h4 {color: var(--secondary);transition: 0.3s;} 

.blog_right_wrapper{       border-left: 1px solid var(--card_border_color); height: 100%;}
.blog_right_body .search-astro-icon {
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 9;
}
.blog_right_body{padding: 0px 15px;}
.blog_type{background-color: var(--card_hover_bg); box-shadow: var(--card_boxshadow);   border: 1px solid var(--card_border_color); border-radius: 10px; margin-bottom: 15px; }
.blog_type_head{padding: 10px 15px;}
.blog_type_head h4{font-size: 16px;}
.blog_type_body{padding: 15px;}
.blog_type_body .MuiStack-root{flex-wrap: wrap; column-gap: 7px; row-gap: 10px;}
.blog_type_body .MuiChip-root{background-color: var(--secondary); border-radius: 7px; flex: 1; text-transform: capitalize;}
.blog_type_body .MuiChip-root:hover{background-color: var(--primary);}
.blog_type_body .MuiChip-label {font-size: 15px; color: #fff;}
/*blog page css end*/

/*contactus page css start*/
.contact-us{ 
background-repeat: no-repeat;background-size: cover;} 
.contactagree .form-check label {font-size: 12px;line-height: 20px;font-weight: 400;}
.contactagree .form-check{display: flex; gap: 10px; align-items: center; padding: 0; margin-top: 10px;}
.help-image img {width: 100%;}
/*contactus page css end*/


/*footer page css start*/
.ownerbg {background-color: rgba(255, 245, 250, 0.08);}
.card {    background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  box-shadow: var(--card_boxshadow);
  border-radius: 15px; overflow: hidden;}
.contact-form-head{padding:15px  15px ;}
.contact-form-head h4{margin-top: 15px;font-size: 18px;line-height: 28px;font-weight: 500;}
 .getin-touch{margin-top: 15px; display: flex;  row-gap:0px; column-gap: 15px; padding: 0px 15px; flex-wrap: wrap;}
 .address{display: flex; align-items: center; gap: 5px; color: var(--text_muted);}
.address h5{font-size: 12px; margin-bottom: 0px;}
.address p{font-size: 12px; margin-bottom: 0;  color: var(--text_muted);}
.contact-persone a{font-size: 12px;}
.contact-link {display: flex;align-items: center;gap: 7px; } 
.contact-link svg {    font-size: 13px;} 
footer{ border-top: 1px solid var(--card_border_color);  padding: 25px;}
.footer-logo{margin: 15px 0px;}
.aboutcompany p{font-size: 14px;}
.Prepp-Community {margin-bottom: 25px;}
.Prepp-Community h5 {font-size: 16px;font-weight: 500;}
.social {margin-top: 15px;}
.social ul{display: flex; align-items: center; gap: 15px;}  
.social li a {color: var(--other_text_color);transition: 0.2s linear;width: 34px;height: 34px;border-radius: 50px;font-size: 22px;display: flex;align-items: center;justify-content: center;background: var(--card_hover_bg);border: 1px solid var(--card_border_color);}
.social li a:hover {background-color: var(--primary); color: #fff; border-color: var(--primary);}
.downloadlink{margin-top: 15px;}
.downloadlink ul{display: flex;    gap: 15px;} 
.footer-all-links {padding-left: 50px;margin: 15px 0px;display: grid;column-gap: 20px;row-gap: 40px;grid-template-columns: repeat(auto-fill, minmax(150px, 1fr ));}
.footer-menu-head h5{   font-size: 16px;font-weight: 500;}
.footer-menubox ul {padding: 15px 0px;}
.footer-menubox ul li a{font-size: 14px; color: var(--other_text_color); display: block; padding: 5px 0px;}
.footer-menubox ul li a:hover{color: var(--secondary);}

.bottom-footer{background-color: var(--bottomfooterbg); padding: 15px;}
.bottom-footer-box {display: flex;align-items: center;justify-content: space-between;}
.terms-link ul {display: flex;align-items: center;}
.terms-link ul li a {color: var(--other_text_color);font-weight: 500;padding: 0px 10px; border-right: 1px solid var(--color500);}
.terms-link ul li a:hover{color: var(--secondary);}
.terms-link ul li:last-child a{border: none;}
.terms-link ul li {font-size: 14px;}
/*footer page css end*/




/*404 page css start*/
.errorpage {min-height: 100vh;padding: 20px 0;box-sizing: border-box;width: 100%;height: 100%;text-align: center;overflow: hidden;max-height: 100vh;z-index: 111;position: absolute;top: 0;left: 0;}
.errorlogo {width: 200px;margin: 0 auto;margin-bottom: 150px;}
.errorlogo img {width: 100%;}
.errorpage__content {position: absolute;top: 50%;left: 50%;width: 100%;transform: translate(-50%, -50%);}
.message__title {font-weight: 600;text-transform: uppercase;letter-spacing: .2em;font-size: 5.6rem;max-width: 960px;margin: 0 auto;}
.message__title2 {font-size: 28px;font-weight: 600;}
.message__text {display: block;font-size: 19px;padding: 0 60px;max-width: 920px;margin: auto;margin-top: 25px;line-height: 33px;}
.errorpagebtn {margin-top: 20px;}
.errorpagebtn .btn {padding: 15px 25px;font-size: 18px;text-transform: none;margin-top: 30px;}
.matrixeffect {width: 100%;position: absolute;z-index: 9;height: 100%;top: 0;}
.matrixeffect {z-index: 1;opacity: .3;}
.error403{width: 500px; margin: 0 auto;}
.error403 img{width: 100%;}
/*404 page css end*/
/*plan page css start*/
.fs26{font-size: 26px !important; }
.plan {        background: linear-gradient(180deg, rgba(244, 227, 210, 1) 0%, rgba(254, 244, 234, 1) 73%, rgba(255, 255, 255, 1) 100%);}
.blur{filter: blur(3px);-webkit-filter: blur(5px);}
.minibanner {  padding:70px  0px;  position: relative;display: flex;align-items: center;     }
.minibanner-content-box {
  display: flex;
  align-items: center;
}
.popularitem .btn-default{background-color: #fff; border-color: #fff;}
.popularitem .btn-default:hover{background-color:#eee; border-color: #eee;  color: var(--colordark);  }
.minibanner-content {
  text-align: center;
  color: var(--colordark);
}
.minibanner-content h2 {
  font-size: 38px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 15px;
  color: var(--colordark);
}
.minibanner-content p {
  font-size: 18px;
  line-height: 32px;
  color: var(--colordark);
}
 
/* .minibanner-image {
 
  width: 50%;
} */
.minibanner-image img {
  height: auto;
  max-width: 100%;
  width: 100%;
  max-height: 500px;
  
}
.comon-inner-banner h2 {font-weight: 600;margin-bottom: 15px;line-height: 67px;font-size: 50px;}
.comon-inner-banner p {font-size: 24px;}
 
.plan-duration .MuiFormControlLabel-root , .plan-duration .MuiSwitch-root{margin: 0 !important;    width: 55px;}
.plan-duration {display: flex;align-items: center;justify-content: center;gap: 15px;margin-top: 20px;}
 
.duration.active{font-weight: 600; }
.ldplan {z-index: -1;position: absolute;}
.ldplan {width: 80px;height: 387px;}
.ldplan:first-of-type { top: -20px;left: 45px;}
.ldplan:nth-of-type(2) {left: 20%;bottom: 40px;opacity: 0.5;}
.ldplan:nth-of-type(3) {right: 25%;bottom: 40px;opacity: 0.5;}
.ldplan:nth-of-type(4) {display: block;top: -20px;right: 45px;}
.ldplan:nth-of-type(5) {display: block;left: 0%;bottom: -100px;}
.ldplan:nth-of-type(6) {right: 8%;bottom: 50px;transform: unset;}
.lddeg, .ldcol {width: 100%;height: 100%;}
.lddeg {position: relative;-webkit-mask-image: linear-gradient(180deg,#000,transparent);mask-image: linear-gradient(180deg,#000,transparent);}
.ldcol {position: absolute; border-radius: 50px;background: linear-gradient(180deg,#e3e5ff,rgba(0,211,127,0));}
.ldimg { position: absolute;height: 100%;width: 100%;border-radius: 50px;mix-blend-mode: overlay;opacity: .3;/* background-image: url(../images/do_not_delete_noise.png); */}
.plan-tables-container { display: flex;gap: 15px;margin-bottom: 20px;}
.plan-table-item {padding: 15px 0px ;      box-shadow: var(--card_boxshadow);
  border: 1px solid var(--card_border_color);
  background-color: var(--card_bg); border-radius: 10px;flex: 1;}
.plan-cost h3{font-size: 22px; font-weight:600; margin-bottom: 13px;}
.plan-title {text-align: center;display: flex;flex-direction: column;row-gap: 10px;margin-top: 15px;padding: 0px 15px;height: 110px;}
.plan-title h3 {font-weight: 600;margin-bottom: 0;text-transform: capitalize;}
.plan-cost-box{margin: 25px 0px; text-align: center;  padding: 0px 15px;}
.plan-cost-box span{font-size: 11px;}
.plan-cost {display: flex;align-items: center;justify-content: center;gap: 10px;}
.plan-cost h5 { text-align: center;margin-bottom:0px;font-size: 34px;font-weight: 800;}
.pricing-description {font-size: 15px;}
.plan-cost small { font-size: 18px;}
.plan-btn { padding: 15px 15px;}
.plan-btn .btn{margin: 0;}
.popularitem{background-color: var(--secondary);  position: relative;  overflow: hidden;  color: #fff; }
.popularitem .plan-title h3 , .popularitem .plan-cost h5{color: #fff;}
.plantagline{color: #676ff0;  font-weight: bold;}
.popularitem .plantagline {color: #fff;font-weight: bold;}
.bubble {position: absolute;top: -0px;left: 0;}
.bubble img {width: 300px;opacity: 0.1;}
.popularitem .ldplan:nth-of-type(1){top: -185px;left: 0;right: inherit;bottom: inherit;opacity: 0.2;}
.popularitem .ldplan:nth-of-type(2){top: inherit;left: inherit;right: 0;bottom: -185px;opacity: 0.2;}
.bgpopular{background: #5962dd;}
.item-features {margin-top: 15px;padding: 15px;}
.item-features h4{font-size: 14px; font-weight: 500; margin-bottom: 30px;   padding-bottom: 10px;  }
.item-features h4 small{color: var(--primary);  }
.bggray{background: #e0e6ee;}
.item-features ul{padding: 0; margin: 0;}
.item-features ul li {display: flex;align-items: center;gap: 7px;margin-bottom: 20px;font-size: 15px;justify-content: space-between;}
.item-features ul li svg{font-size: 22px;}
.checkcol svg{ color: #66ce8a;}
.Uncheckcol svg{ color: #ce6666;}
.item-features ul li span {order: 2;margin-left: 40px;}
.Features-table table tr th ,.Features-table table tr td{text-align: center; font-size: 15px; padding: 12px 8px;   vertical-align: middle;}
.f-check{    font-size: 18px;color: #66ce8a;}
.f-uncheck{color: #ff6060;}
.Features-table table tr th:first-child, .Features-table table tr td:first-child{text-align: left; width: 46%;}
.Features-table table tr th:nth-child(2), .Features-table table tr td:nth-child(2){ width: 18%;}
.Features-table table tr th:nth-child(3), .Features-table table tr td:nth-child(3){ width: 18%;}
.Features-table table tr th:nth-child(4), .Features-table table tr td:nth-child(4){ width: 18%;}
.Features-table table tr th {background: #f9f9f9;border: 1px solid #eee;text-transform: uppercase;font-weight: 600;}
.progressBar {transition: width 1s ease-in-out;} 
.addons-box { background: #f5f7fa;padding: 0px 30px 30px 30px;}
.addons-title{padding: 30px 0px; text-align: center;}
.addons-title h2{      font-weight: bold; text-transform: capitalize;color: #0f172b;margin-bottom: 0px;}
.add-ons-row {display: flex;justify-content: space-between;align-items: center;background-color: #fff;padding: 10px 0px;border-radius: 50px;text-align: center;}
.add-ons-row label {font-weight: 600;display: flex;align-items: center;gap: 7px;} 
.add-ons-row label  svg {font-size: 18px;color: #66ce8a;}
.amount-value {font-weight: 600;font-size: 16px;color: #409de2;display: flex;align-items: center;gap: 3px;}
.amount-value small {font-size: 12px;}
.currency-symbol {position: relative;top: -3px;}
.currency-symbol svg{font-size: 16px;}
.add-ons-flex {display: flex;}
.flex1{flex: 1; padding: 30px; background-color: #fff; border: 1px solid #eee;   position: relative; margin: 0px 15px; margin-bottom: 15px; }
.flex1:after{content: ''; width: 1px; background-color: #d8dce3; height: 100%; position: absolute; top: 0; right: -15px;  }
.flex1:last-child::after{display: none;}
.flex1:last-child{border: none;}
.duration { position: relative;}
.duration small {position: absolute;left: 65px;width: 100px;top: 3px;} 
.addon-info {/* background: #e9ebff; *//* padding: 12px 20px; */border-radius: 4px;margin-bottom: 15px;/* font-weight: 600; */text-align: center;line-height: 27px;margin-top: 17px;}
.credittitle{margin-bottom: 15px; text-align: center;}
.credittitle h3 {font-size: 18px;font-weight: 700;} 
/*plan page css end*/

/*faq page css start*/
.faq-wrapper .MuiPaper-root {margin-bottom: 15px !important;box-shadow: rgba(16, 41, 48, 0.07) 0px 0px 12px 0px !important; border: none !important;background-color: #fff !important;}
.faq-wrapper .MuiAccordionSummary-content h5{font-size: 16px; font-weight: 500;}
.faq-wrapper .MuiPaper-root:before{background-color: none !important; position: inherit !important;}  
/*faq page css start*/
 


/*subscription payment page css start*/
.payment-details {padding:30px 0px 50px 0px;}
.payment-head {margin-bottom: 25px;}
.payment-head h2{font-size: 24px;font-weight: 600;}
.Orderid {display: flex; justify-content: space-between;}
.Orderid h5 {font-size: 16px;}
.Orderid h5 b{font-weight: 600;}
.payment-details .card-header {    padding: 15px 15px;box-shadow: none;border: none;     background: var(--card_header_bg); border-radius: 0;} 
.address-head {margin-bottom: 20px;}
.address-head h4 {font-weight: 600;font-size: 18px;}
.summary-line {display: flex;margin-bottom: 15px;}
.summary-flex-1 {flex: 0 1 180px;text-align: left;color: var(--other_text_color);}
.summary-line span {font-size: 14px;}
.summary-flex-1 b {  font-weight: 400;}
.gst b{font-weight: 600;}
.summary-flex-3 {flex: 1 0 50px;text-align: right; font-weight: 600;color: var(--other_text_color);}
 .cupon-code-area {padding: 15px 0px;}
.cupon-code {display: flex; gap: 10px; align-items: center;}
.summary-foot {border-top: 1px solid #171a29;border-bottom: 1px solid #171a29;padding: 10px 0px;display: flex;justify-content: space-between;align-items: center; color: var(--other_text_color);}
.summary-btn {    margin: 20px 0px 10px 0px;text-align: center;}
.total-cost {font-size: 14px;font-weight: 600;text-transform: uppercase;}
.total-summary-amount {font-weight: 600; font-size: 18px;}  
.paymentsumery .card-header {padding: 15px;border-bottom: 1px solid #eee;background: #fff; color: var(--colorwhite); text-align: center;}
 .payment-icon i { font-size: 55px; margin-bottom: 10px;}
.paymentmessage ul li { display: flex; align-items: center;justify-content: space-between;border-bottom: 1px solid #eee;padding:15px 15px;}
 .paymentres {    font-weight: 500;color: var(--colordark);}
.paymentbtn {margin: 30px 0px 15px 0px; padding: 15px; display: flex;gap: 15px;}
.approve .card-header{background-color: #00d78e; color: var(--colorwhite);}
.Standard {background-color: #cfe2ff !important;color: #084298;}  
.pro {background-color: #fff3cd !important;color: #664d03;} 
.max {background: #f5ddcc !important;color: #5b2600;} 
.Incredible {background: #c4f5a5 !important;color: #215600;}
.payment-details-status{    height: calc(100vh - 138px); display: flex; align-items: center; justify-content: center;}


[data-theme="dark"] .MuiSwitch-track{background-color: #fff;}
/*subscription payment page css end*/





/*success popup  css start*/
.commonpopup{position: absolute; top: 50%; left: 50%; transform:translate(-50%, -50%); box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px; background-color: #fff;  border-radius: 15px;  }
.popup-w35{width: 35%;}
.popup-w50{width: 50%;}
.popup-w75{width: 75%;}
.popup-w85{width: 85%;}
.popup-w35,.popup-w50,.popup-w75,.popup-w85{max-width: 700px;} 
.create-team-wrapper{padding: 15px 0px;}
.popuptitle {margin-bottom: 15px;}
.modal-common  .modal-header {border-bottom: none;text-align: center;display: initial;padding-bottom: 30px;}
.commonpopup .card-title { margin-bottom: 30px;}
.commonpopup .card-title span{font-size: 14px;}
.modal-common .icon-box {display:flex;color: #fff;margin: 15px auto 10px auto;left: 0;right: 0;top: -55px;width: 55px;height: 55px;border-radius: 50%;z-index: 9;background: transparent;padding: 15px;text-align: center;border: 1px solid #eee;box-shadow: 0px 2px 2px rgb(0 0 0 / 10%);display: flex;align-items: center; justify-content: center;}
.modal-common .icon-box i {font-size: 30px;position: relative;top: 3px; }
.icon-box i {  color: #000;} 
.modal-confirm .icon-box i { color: #82ce34;}
.modal-confirm   h4 { color: #82ce34; font-size: 20px; font-weight: 800; margin-bottom: 0px;} 
.modal-common .model-content{margin-top: 30px;}
.modal-common  h3 {color: #000;    font-weight: 600;font-size: 18px;margin-bottom: 10px;}
.model-content p{font-size: 16px;}
.modal-btn{display: flex; gap: 10px; justify-content: center; margin-top: 30px;}
.modal-btn .btn{flex: 1;}
.modal-header{background: #f1f5f8; position: relative;  padding: 15px;border-bottom: 1px solid #eee;justify-content: center;}
.modal-header h4{font-weight: bold;}
.modal-body{padding: 25px;}
.closepopup {background-color: #eee;border: 1px solid #000;border-radius: 50%;color: #000;font-size: 16px;height: 25px;width: 25px;line-height: 20px;margin: 0;opacity: 1;padding: 0;position: absolute;right: 15px;top: 15px;z-index: 99;display: flex;align-items: center;justify-content: center;}
.deactive .icon-box {border: 1px solid #bb2d3b;}
.deactive .icon-box i {color: #bb2d3b;} 
.card-item__content .card-title h2{font-size: 18px; margin-bottom: 5px;    font-weight: 600;}
.add_brain-step_wrapper{margin-top: 30px;}
.add_brain_body {padding: 30px 15px;}
.step-successbox {padding: 50px 15px;}
.success-stepicon {padding: 12px;width: 200px;height: 200px;margin: 0 auto;}
.success-stepicon img {width: 100%;height: 100%;}
.success-stepmessage {text-align: center;margin: 20px 0px;} 
/*popup  css start*/



/*login page css start*/
/* .datetimepicker input{padding: 10px 14px;} */
.datetimepicker .MuiStack-root{padding: 0;}
.bglogin { height: 100%;} 
.login_wrapper{height: 100vh; font-size: 14px;}
.login_flex{display: flex; height: 100%;}
.login_left{ height: 100%; width: 50%;  flex-shrink: 0; }
.auth-bg {height: 100vh;overflow: hidden;padding: 15px 50px;display: flex;flex-direction: column;justify-content: center;align-items: center;}
.login_logo {     padding: 10px;}
.login_title{text-align: center; margin-bottom: 50px; margin-top: 20px;}
.login_title h2{color: var(--primary);   letter-spacing: 3px;  font-size: 4vh; font-weight: 600; margin-bottom: 1vh;}
.login_title h5{color: var(--other_text_color); font-weight: normal; line-height: 34px; font-size: 22px;   }
.sliderimage {position: relative;}
.sliderimage img {width: 100%;}
.login_right{flex-grow: 1;}
.auth-full-page-content {overflow: auto;height: 100vh;max-height: calc(100vh - 0px);display: flex;    background: var(--loginbg);}
.form-width {max-width: 650px;width: 100%;display: flex;  flex-shrink: 0;margin: 0 auto;padding: 25px 25px;}
.auth-content {   border-radius: 15px;padding: 0px 30px 30px 30px;position: relative;z-index: 1;    width: 100%;}
.auth-content .MuiIconButton-root{color: rgb(255 255 255 / 100%);}
.welcometext{text-align:center;}
.welcometext h5 {font-weight: 600;}
.welcometext p {font-size: 16px;}
.btn-google {color:var(--other_text_color);border-radius: 10px; text-align: center;padding: 10px 10px;font-size: 14px;display: flex;align-items: center;justify-content: center;gap: 10px;   border: 1px solid var(--card_border_color);box-shadow: var(--card_boxshadow); background-color: var(--bodybg);} 
.btn-google:hover{color: var(--other_text_color); background-color: var(--card_bg);}
.btn-google img{width:20px; height: 20px;}   
.other-login-options{    display: grid;grid-template-columns: 1fr 1fr; gap: 10px; margin: 25px 0px 15px 0px;}
.logincorporate label {font-size: 13px;font-weight: 500;}
.otp-alt {padding: 6px 15px;margin-bottom: 10px;}
.alert-message {display: flex;align-items: center;gap: 10px;}
.alert-message svg {font-size: 18px;}
.alert-message p {font-size: 12px;}
.error svg{font-size: 18px;} 
.PhoneInputCountrySelect {position: absolute;top: 0;left: 0;height: 100%;width: 100%;z-index: 1;border: 0;opacity: 0;cursor: pointer;}
.PhoneInput {display: flex;align-items: center;}
.worldwidephone {border: 1px solid var(--card_border_color) !important;       background: var(--inputbg); height: 53px;border-radius: 5px;}
.PhoneInputCountry{padding: 0px 8px;}
.worldwidephone .PhoneInputInput{    flex: 1 1;min-width: 0;font-size: 13px;letter-spacing: inherit;color: currentColor;padding: 4px 0 5px;border: 0;box-sizing: content-box;background: none;height: 1.4375em;margin: 0;-webkit-tap-highlight-color: transparent;display: block;min-width: 0;width: 100%;-webkit-animation-name: mui-auto-fill-cancel;animation-name: mui-auto-fill-cancel;-webkit-animation-duration: 10ms;animation-duration: 10ms;    padding: 15.5px 14px;border-left: 1px solid var(--card_border_color);}
.error {font-size: 12px;}
.forgotpass a, .notmember a , .resentotp a{font-size: 14px; color: var(--other_text_color); font-weight:600;}
.auth-pass-inputgroup .MuiFormControl-root{width: 100%;}

.social_media_links {
  text-align: center;
  margin-top: 25px;
}

.social_media_links ul {
 justify-content: center;
}
/*login page css end*/
.g_signup{ padding: 35px 0px;}
.g_signup_logo {
  text-align: center;
  margin-bottom: 30px;}

.stepper_bar {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.stepbar_title {
  position: relative;
}
.stepbar_title h5 {font-size: 16px;}
.stepbar_count {
  position: absolute;
  top: -9px;
  right: 10px;
}
.stepper_bar .MuiLinearProgress-root{height: 6px;width: 100%;}

.g_signup_step_head {
  text-align: center;
  padding: 30px 0px;
}

.g_signup_step_head h3 {
font-weight: 600;
font-size: 22px;
}

.gender_box_row {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.gender_box_cta {
  width: 145px;
    height: auto;
    padding: 18px 0px;
    background: var(--card_bg);
    border: 1px solid var(--card_border_color);
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    transition: 0.3s;
}
.gender_box_cta.active{    background-color: #ffe0e0;
 
}
.gender_box_cta.active .gender_name{ color: var(--colordark);}
.gender_name{font-size: 16px; font-weight: 500; color: var(--other_text_color);}
.gender_icon svg{font-size: 80px;}


.g_signup_step_button{display: flex; justify-content: center; gap: 15px; margin-top: 30px;}

.stepper_bar  .MuiPaper-root{background-color: transparent; color: var(--other_text_color);}
.datetimepicker input , .selectcity input {text-align: center;}











.occasion-tab {margin-bottom: 30px}
.occasion-tab .nav-tabs {border-color: var(--card_border_color);}
.occasion-tab .nav-tabs .nav-item {margin: 0 10px 0 0}
.occasion-tab .nav-tabs .nav-link {background: transparent;border: none;border-bottom: 2px solid transparent;color: var(--other_text_color);font-size: 16px;font-weight: 500;text-align: center;}
.occasion-tab .nav-tabs .nav-link.active {background-color: transparent;border-color: #f19e4b;color: #f19e4b;font-weight: 500}

.terms-content  {
   
  border-radius: 15px;
  padding: 25px 25px;
  margin-bottom: 30px;
  height: 100%;
  background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  box-shadow: var(--card_boxshadow);
}
/*astrochat page css start*/ 
.astro_chatbot_wrapper{    height: calc(100vh - 73px);  }
.astro_flex {display: flex;height: 100%;}
.asrtro_aside{ width: 290px; flex-shrink: 0;  border-right: 1px solid var(--card_border_color); }
 .asrtro_aside_flex{display: flex; flex-direction: column; 
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 73px);   
 }
 /* .astro_history{flex: 1 1;} */
 .history_head {padding: 10px 10px;}
.history_title {display: flex;align-items: center;gap: 10px;width: 100%;transition: all 0.3s;justify-content: center;}
 .history_title h3{font-size: 16px; color: #fff;}
.chat_avtar_history {padding: 15px 10px;     background-color: var(--card_bg); 
  border: 1px solid var(--card_border_color); margin:0px  15px;border-radius: 10px;overflow: hidden;position: sticky;top: 5px;z-index: 99;     border-image: linear-gradient(45deg, #4399bf, #f16122) 1;}
.aiastro_title {text-align: center;margin-bottom: 17px; color: var(--colordark); display: none; }
.avtar_image {width: 130px;margin: 0 auto;}
.avtar_image img {width: 100%;}
.astroname {text-align: center;margin-top: 15px;  font-weight: 600; margin-bottom: 10px;font-size: 16px;   }
.astro_avtar_pera{ line-height: 22px;   text-align: center;overflow: hidden;display: -webkit-box;
 -webkit-line-clamp: 5;-webkit-box-orient: vertical;}
.sidebar-history {   border-radius: 10px;       background-color: var(--card_bg);
  border: 1px solid var(--card_border_color); margin: 15px; margin-top: 15px; }
.sidebar-history-body {padding: 0px;}
.sidebar-history-body ul li {position: relative;}
.sidebar-history-body ul li a {padding: 5px 10px;  position: relative;   display: block; color: var(--other_text_color); border-radius: 4px; border: 1px solid transparent;     font-weight: 400;}
.sidebar-history-body ul li  .history_line{width: 88%;     overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;}
.sidebar-history-body ul li a.active, .sidebar .sidebar-menu .sidebar-history-body  ul li a:hover {background: #ededed;color: var(--colordark);  }
.sidebar-history-body .list_box {margin-bottom: 10px;}
.sidebar-history-body .list_box ul ul{padding: 0px 10px;}
.sidebar-history-body .list_title { margin: 12px 10px 5px 10px;font-weight: 600;color: var(--primary);text-transform: capitalize;}
.sidebar-history::-webkit-scrollbar-track{ border-radius: 10px;background-color: transparent;}
.sidebar-history::-webkit-scrollbar{width: 5px;background-color: transparent;}
.sidebar-history::-webkit-scrollbar-thumb{border-radius: 10px; background-color: transparent;}
.history_action {position: absolute;top: 0px;right: 0;display: flex;align-items: center;justify-content: center;height: 100%;opacity: 0;}
.history_action a{   background-color: rgba(237,237,240,0.2);   padding: 5px 7px !important; margin: 0 !important; color: var(--colordark) !important;}
.history_action a svg{font-size: 20px;}
.sidebar-history-body ul li:hover   .history_action{opacity: 1;} 
.sidebar-history-body ul li a.active,   .sidebar-history-body ul li a:hover { background-color: #f5e1ce; color: var(--colordark);}
.nochats {
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 16px;
}
 
.asrtro_chat_chart_box {
  width: 350px;
  
  flex-shrink: 0;
 
  border-left: 1px solid var(--card_border_color);
 
}

.asrtro_chat_chart_body {
  padding: 15px;
  min-height: auto;
  max-height: calc(100vh - 73px);
  overflow: auto;
}
.chat_chart_cta {
  background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  border-radius: 15px;
  margin-bottom: 15px;
  overflow: hidden;
}
.chartcta_title h3 {
  font-size: 14px;
  font-weight:600 ;
}
.chat_chartcta_head {
  padding: 10px 15px;
  border-bottom: 1px solid var(--card_border_color);
  text-align: center;
  background: var(--card_header_bg);
}
.astro-aside_cta_body {
  padding: 15px;
}
.kundli_image img {
  width: 100%;
}
.table_flex {
  display: flex;
}
.table_box {
  flex: 1;
  padding-top: 15px;
  border-right: 1px solid var(--card_border_color);
}
.table_box:last-child{border: none;}
.table_row {
  display: flex;
 
  flex-direction: column;
  padding: 0px 15px 0px 15px;
  margin-bottom: 5px;
}
.table_label {
  font-weight: 600;
  color: var(--other_text_color);
 
 
}
.table_value {
  color: var(--text_muted);
  font-size: 13px;
}
 
 
.asrtro_chat_right {
  width: 100%;
  flex-grow: 1;
  margin-left: 0px;
}
.deskhome_chat_wrapper {
  display: flex;
  height: 100%;
}
.desk_chat_wrapper {
 
  flex: 35 1;
  position: relative;
}
.desk_chat_head {
  
  border-bottom: 1px solid var(--card_border_color);
  padding: 6px 15px 6px 15px;
  height: 35px;
  display: flex;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 55px;
  z-index: 999;
}
.top-chatbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  position: sticky;
  top: 75px;
  z-index: 999;
}
.chat_title {
  cursor: pointer;
  flex: 1 1;
}
.chat_title h2 {
  font-size: 16px;
  font-weight: 500;
 
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 65%;
}
.chattime {
  font-size: 12px;
}

.viewer-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}
.viewer-actions a {
  color: var(--colordark);
}


.desk_chat_panal {
  top: 50px;
  width: 100%;
  max-width: 100%;
  right: 0;
  bottom: 3px;
  /* position: absolute; */
  z-index: 1;
  max-height: 100%;
  transition: height 0.2s ease 0s, max-width 0.2s ease 0s, min-width 0.2s ease 0s, bottom 0.2s ease 0s, right 0.2s ease 0s;
  display: flex;
}
.desk_chat_inner_box {
  flex-grow: 1;
  display: flex;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-duration: 250ms;
  animation-name: iYtCBa;
}
.desk_chat_contentwrapper {
  flex-grow: 1;
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.desk_chat_contentbox {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow: hidden;
  overscroll-behavior-y: contain;
}
.desk_chat_bodydown {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

}
.desk_chat_body_Inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  visibility: visible;
}
.desk_message_list_container {
  flex-grow: 1;
  flex-shrink: 1;
  /* overflow: hidden scroll; */
  display: flex;
  flex-direction: column;
  overscroll-behavior-y: contain;
  /* border-inline-end: 6px solid rgb(255, 255, 255); */
  
  max-height: calc(100vh - 227px);
  min-height: calc(100vh - 227px);
  overflow-y: auto;
  overflow-x: hidden; 
}
.desk_message_list {
  min-height: 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 10px;
  padding-top: 6px;
  font-size: 15px;
  transition: 0.3s;
}
.chat_message {
  padding: 30px 20px;
}
.chat-box {
  max-width: 1580px;
  margin: 0 auto 30px auto;
}
.chat-flex {
  display: flex;
  gap: 15px;
}

.chat_avtar .MuiAvatar-root {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
.chat_content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.chat_sender {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
   
}
.chat_responcebox {
  padding-left: 36px;
}
.answer_content{margin-top: 5px; font-size: 14px;line-height: 26px;   word-break: break-word; }
.list-outside {list-style-position: outside;}
.list-disc {list-style: disc;}
.answer_content ul , .answer_content ol {padding-left: 2rem;    margin-bottom: 15px;}
.answer_content ul ol , .answer_content ol ul {padding-left: 15px;    }

.answer_content ul li p , .answer_content ol li p{margin-bottom: 0px;}
.answer_content h1, .answer_content h2, .answer_content h3 , .answer_content h4, .answer_content h5, .answer_content h6{    font-size: 18px;margin: 15px 0px;}
.answer_content :where(li):not(:where([class~=not-answer_content],[class~=not-answer_content] *)) {margin-top: 0.5em;margin-bottom: 0.5em;}
.answer_content :where(ul>li):not(:where([class~=not-answer_content],[class~=not-answer_content] *)) {padding-left: 0.375em;}
.answer_content :where(ol>li):not(:where([class~=not-answer_content],[class~=not-answer_content] *)) {padding-left: 0.375em;}
.answer_content :where(ul>li):not(:where([class~=not-answer_content],[class~=not-answer_content] *))::marker {color: #64645f;}
.answer_content ul li , .answer_content ol li{list-style: disc; }
.answer_content :where(strong):not(:where([class~=not-answer_content],[class~=not-answer_content] *)) { font-weight: 550;}

.answer_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0px 0px;
}
.left_action, .right_action {
  display: flex;
  align-items: center;
  gap: 10px;
}
.regeneratechat {
  display: flex;
  align-items: center;
  
}
.changechat_flex {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
}
.changechat_flex button svg {
  margin: 0;
  font-size: 13px !important;
}
.btn svg, Button svg {
  font-size: 18px !important;
  margin-right: 3px;
}
.likedislike {
  display: flex;
  align-items: center;
  gap: 0px;
}
.likedislike .MuiCheckbox-root {
  padding: 6px;
}
.likedislike svg {
  font-size: 14px;
  color: var(--other_text_color);
}

.regeneratechat svg{ color: var(--other_text_color);}


.bottom_chat_wrapper {
  position: sticky;
  bottom: 0px;
  width: 100%;
  left: 350px;
  right: 0;

  z-index: 99;
  transition: all 0.5s;
  box-shadow: var(--card_boxshadow);
  background-color: var(--card_bg);
  border-top: 1px solid var(--card_border_color);
 
}

.stop_generating {
  position: absolute;
  top: 20px;
  z-index: 999;
  left: -55px;
}
.chat_form {
  /* position: relative; */
  flex-shrink: 0;
  max-width: 1680px;
  margin: 0 auto;
  padding: 12px 20px;
 
}

 
.access_message {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0;
    background: var(--card_bg);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    left: 0;
    right: 0;
}
.access_message  h4{margin-bottom: 10px !important; font-size: 14px !important;}

.accesspersonalize{position: inherit; padding: 15px; height: 150px; border-radius: 15px;background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  box-shadow: var(--card_boxshadow); display: flex; align-items: center; justify-content: center;}

  .accesspersonalize  .dashboard_plan_button{    top:35%;}
.access_message .btn {
  font-size: 12px;
  padding: 5px 15px;
}
.chat_form_box {
  position: relative;
  display: flex;
  flex-direction: column;

  background: #fff;
  border: none;
  --tw-bg-opacity: 1;
  border-radius: 5px;
  background-color: transparent;
  box-shadow: none;
  transition: border-color 0.2s ease 0s;
}

.chat_form_flexbox {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
  transition: border 200ms ease 0s, box-shadow 200ms ease 0s; 
  border-radius: 15px; 
  overflow: hidden;
  background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  min-height: 50px;
}

.messageform {
  align-items: center;
  display: flex;
  flex: 1 1;
  position: relative;
}
.messageform textarea {
  max-height: 150px;
  overflow: auto !important;
  padding-right: 20px;
  color: var(--other_text_color) !important;
  font-size: 14px !important;
  font-weight: 300;
  font-family: 'Poppins';
  background-color: transparent !important; 
}
.messageform .MuiOutlinedInput-notchedOutline {
  border: none !important;
  border-color: #484f68;
}
.listeningvoice {
  position: absolute;
  top: 0;
  width: 100%;
  display: none;
  background: #fff;
  transition: 0.2s;
}
.listeningvoice .MuiLinearProgress-root {
  background-color: #8b92ff;
}
.listeningvoice .MuiLinearProgress-bar {
  background: #dcd7f4;
}
.listeningvoice .MuiLinearProgress-bar {
  background: #dcd7f4;
}
.listeningvoice.listening {
  display: block;
}
.typing-box {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #ccc;
  font-size: 14px;
}
.listeningvoice .typing-box {
  color: #000;
  padding: 15px 25px;
}
.typing_loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: typing 1.2s cubic-bezier(0.42, 0, 0.58, 1) infinite;
  margin: 5px 15px;
  position: relative;
  left: -12px;
}
.desk_chat_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px 0px 15px;
  gap: 15px;
}
.send_btn {
  justify-content: center;
  display: flex;
  gap: 15px;
  align-items: center;
  vertical-align: middle;
}
.senderbtn {
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border: none;
  outline: none;
  position: relative;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-image: none;
  background-color: var(--primary);
  transition: all 0.3s;
  font-size: 14px;
}
.senderbtn.listening {
  animation: pulse 1s infinite;
}

@keyframes pulse {0% {transform: scale(1);}50% {transform: scale(1.1);}100% {transform: scale(1);}}
.pulse-ring{content: '';width: 35px;height: 35px;background: #dc3545;border: 5px solid #dc3545;border-radius: 50%;position: absolute;top: 0px;left: 0px;animation: pulsate infinite 1.5s;display: none;z-index: -1;}
.pulse-ring.listening {display: block;  }
@keyframes pulsate {0% {-webkit-transform: scale(1, 1);opacity: 1;}100% {-webkit-transform: scale(1.3, 1.3);opacity: 0;}}
/* Vendor Prefixes */
@-webkit-keyframes pulsate {0% {-webkit-transform: scale(1, 1);opacity: 1;}100% {-webkit-transform: scale(1.3, 1.3);opacity: 0;}}
@-moz-keyframes pulsate {0% {-webkit-transform: scale(1, 1);opacity: 1;}100% {-webkit-transform: scale(1.3, 1.3);opacity: 0;}}
@-o-keyframes pulsate {0% {-webkit-transform: scale(1, 1);opacity: 1;}100% {-webkit-transform: scale(1.3, 1.3);opacity: 0;}}
.senderbtn:disabled{background-color: #c3c6ff; cursor: no-drop;}
.senderbtn:disabled svg{color: #fff;} 
.senderbtn svg {
  color:#fff;
  margin: 0;
  font-size: 16px;
}


.astro-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
}
 
.chart {
  position: relative;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  border: 2px solid #000;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.center-circle {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color:var(--secondary);
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
 
.icon {
  font-size: 32px;
}
 
.layer {
 
  /* pointer-events: none; 
  display: flex;
  flex-direction: column-reverse; */
  position: absolute;
  width: 100%;
  height: 100%;
  /* clip-path: polygon(50% 50%, 100% 0%, 100% 100%, 50% 50%); */
  transform-origin: 50% 50%;
  transition: background-color 0.3s ease;
}

/* .layer:hover{    background-color: rgba(0, 0, 0, 0.1);} */

.segment {
    border-left: 1px solid #eee;
    text-align: center;
    
}

 
 

 .layer_1 {transform: rotate(0deg);}

.layer_2{transform: rotate(30deg); }

.layer_3 {transform: rotate(60deg);}

.layer_4{transform: rotate(90deg); }
.layer_5{transform: rotate(120deg);}

.layer_6{transform: rotate(150deg); }
.layer_7 {transform: rotate(180deg);}

.layer_8{transform: rotate(210deg); }
.layer_9 {transform: rotate(240deg);}

.layer_10{transform: rotate(270deg); }
.layer_11 {transform: rotate(300deg);}

.layer_12{ transform: rotate(330deg);}

 
 


.info-box {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #000;
  background: #f9f9f9;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/*astrochat page css end*/

/*career page css start*/
.aiproctoring-flex {display: flex;gap: 15px;}
.proctor-box{flex: 1; padding: 1rem 0px;}
.aiproctoring-cta {height: 100%; cursor: pointer;      background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  box-shadow: var(--card_boxshadow);  border-radius: 15px;padding: 25px;display: flex;gap: 22px;align-items: center;}
.proctor-icon  {width: 150px;height: 150px; padding: 18px; text-align: center;border-radius: 50%;background-color: #fff;position: relative;display: flex !important;justify-content: center;align-items: center;flex-shrink: 0;}
.proctor-icon img {width: 100%;}
.proctor-icon  svg{width: 80% !important; }
.proctor-icon::before {content: "";position: absolute;width: 100%;height: 100%;border: 1px dashed rgba(0,0,0,.3);left: 5px;top: 5px;border-radius: 50%;transition: .3s ease-in-out;}
.aiproctoring-cta:hover .proctor-icon::before {left: -5px;top: -5px;transition: .3s ease-in-out;}
.proctor-content p{font-size: 14px; margin-bottom: 0; color: var(--color500);  }
.proctor-content h4 {font-weight: 600;font-size: 18px;margin-bottom: 7px;}
.proctor-content h5 {font-weight: 600;font-size: 14px;margin-bottom: 10px;  line-height: 22px; color: var(--color400);} 
.proctor-box a{color: var(--other_text_color);}
/* .matchmakingbg{    background-color: rgba(255, 59, 65, 1);}
.matchmakingbg  .proctor-icon{ background-color: rgba(255, 59, 65, 1);}

.relationshipcalnderbg{    background-color: rgba(18, 196, 98, 1);}
.relationshipcalnderbg  .proctor-icon{background-color: rgba(18, 196, 98, 1);}
.facematchingbg{     background-color: rgba(157, 67, 194, 1);}
.facematchingbg .proctor-icon{    background-color: rgba(157, 67, 194, 1);}
.Celebritybg{     background-color: rgba(254, 149, 13, 1);}
.Celebritybg .proctor-icon{     background-color: rgba(254, 149, 13, 1);}
.askquestionbg{      background-color: rgba(113, 117, 170, 1);}
.askquestionbg .proctor-icon{      background-color: rgba(113, 117, 170, 1);} */



/*career page css ed*/

/*match making page css start*/
.matchmaking-bannerbg{       background-color: rgba(255, 59, 65, .1); background: rgb(252,223,225);
  background: linear-gradient(180deg, rgba(252,223,225,1) 0%, rgba(253,233,234,1) 73%, rgba(255,255,255,1) 100%);}

  .matchmaking-bannerbg .match_form_head{    background: #f7d4d7;}
.match_form_flex {
  display: flex;
  gap: 15px;
}

.match_result_box {
  max-height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
  overflow: auto;
  background-color: #fffaf5;
  position: sticky;
  top:56px;
 
}
.match_form_box {
 overflow: hidden;
  border-radius: 15px;
  border: 1px solid #bbbbbb;
}
.match_formbox {
  flex-grow: 1;
  width: 80%;
}

.match_form_box{flex: 1;}
.match_form_head {
  padding: 10px 15px;
   
  text-align: center;
}
.match_form_head h4 {
  font-size: 18px;
  font-weight: 600;
}
.match_form_body{padding:30px 15px; background-color: #fff; height: 100%;}
.tools-banner .minibanner-content{width: 85%; text-align: center;   padding-right: 0;}
.tools-banner .minibanner-content-box{flex-direction: column; gap: 0px;}
.match_button{text-align: center;}


.innerpagesection{    max-width: 1440px;
  margin: 0 auto;}
.match_flex{display: flex; }
.match_making_result_left{ width: 430px;}
.march_result_heading{text-align: center; padding: 30px 15px;}
.march_result_heading h2 {
 font-size: 24px;
  font-weight: 600;
  margin-bottom: 7px;
  color: var(--colordark);
}
.march_result_heading h3{font-weight: 400; font-size: 18px; line-height: 26px;}

.other_matches{margin-top: 30px; padding: 15px 15px;}
 
.other_matches-cta {
  display: flex;
  flex-direction: column;
  gap: 5px; justify-content: space-between;
  align-items: center;
  background: #f9f1ea;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;

}
.othermatch_lable{font-size: 15px; font-weight: 500; color: var(--colordark); flex-shrink: 0;}


.other_match_percentage {
  flex-grow: 1;
  text-align: center;
  width: 100%;
}
.other_match_percentage h3{font-size: 18px; font-weight: 600;}
.score_percentage{position: relative; display: flex;     display: flex;
  gap: 15px; align-items: center;}
.score_percentage .percentnumber{ color: var(--colordark); font-size: 18px !important;
  font-weight: 600; display: flex; align-items: center; gap: 5px;}
.score_percentage .percentbar {
  height: 15px !important;
  border-radius: 6px;
  flex-grow: 1;
}

.match_making_result_right{flex-grow: 1;      
  background: #f3f5f8;}

  .simple_tabs .MuiTab-root.Mui-selected {
    color: var(--secondary) !important;
}
.simple_tabs  .MuiTabs-indicator  {background-color: var(--secondary);}
.matchmaking_right_head{padding: 15px; background-color: #fff; border-bottom: 1px solid #eee; position: sticky; top: 56px;}

.matchmaking_right_body{  margin: 10px; background-color: #fff; border-radius: 6px;}

.simple_tabs .MuiTab-root {
  min-height: 38px; 
  color: var(--color900);
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
 
}


.csv-upload { height: 80%; display: block; position: relative; display: flex; align-items: center; justify-content: center;flex-direction: column;  padding: 25px 15px;    row-gap: 10px; text-align: center;}
.csv-upload h5 {font-size: 16px;margin-bottom:8px;}
.csv-upload p{font-size: 11px; margin-bottom: 0;}
.svg-circle{   margin-bottom: 10px; width: 180px; height: 180px; overflow: hidden;    }
.csv-upload svg {font-size: 30px;color: #000000;}
.invitedevider { margin: 15px 0px;text-align: center;font-weight: bold;}
.csv-upload label { display: flex;align-items: center;justify-content: center;height: 100%;width: 100%;flex-direction: column;cursor: pointer;}
.csv-upload input {position: absolute;top: 0;left: 0;right: 0;margin: 0 auto;height: 100%;}
.labeltext { margin-bottom: 4px; font-weight: 600; font-size: 14px;} 
.uploaded-resume{display: flex; align-items: center; justify-content: space-between; width: 100%;}

.face_match .face_box{height: 220px; position: relative;}
.face_box_head {
  padding: 5px;
  text-align: center;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgb(241, 97, 34, 0.7);
  z-index: 9;
  color: #fff;
}
/*match making page css end*/

/*Celebrity Compatibility page css start*/
.width45 {justify-content: center;}
.width45 .match_form_box{width: 50%; flex: inherit;}
.Celebritycompatibilitybg{background: rgb(244,227,210);
  background: linear-gradient(180deg, rgba(244,227,210,1) 0%, rgba(254,244,234,1) 73%, rgba(255,255,255,1) 100%);}
.Celebritycompatibilitybg .match_form_head{    background: #f4e3d2;}
.facematchmaking_bannerbg{background: rgb(237,218,244);
  background: linear-gradient(180deg, rgba(237,218,244,1) 0%, rgba(246,236,249,1) 73%, rgba(255,255,255,1) 100%);}
  .facematchmaking_bannerbg .match_form_head{    background: #eddaf4;}
  .breakupcalculator-bannerbg{background: rgb(241,241,246);
    background: linear-gradient(180deg, rgba(241,241,246,1) 0%, rgba(231,231,251,1) 73%, rgba(255,255,255,1) 100%);}
    .breakupcalculator-bannerbg .match_form_head{    background: #f1f1f6;}
  



.face_wrapper {
  padding: 0px 20px;
}
.face_flex {
  display: flex;
  gap: 15px;
  justify-content: center;
}
.face_box {
width: 100%;
  background: #fff;
  border-radius: 15px;
 overflow: hidden;
  background: #fff;
  height: 300px;
  border: 4px solid var(--primary);
}
.image_card {
  height: 100%;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.image_card img{
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  object-fit: cover;
  text-align: center;
 
  color: transparent;
  text-indent: 10000px;}
/*Celebrity Compatibility page css end*/



/*campaining page css start*/
.reportbanner{    background: linear-gradient(279deg, rgba(46, 63, 70, 1) 0%, rgba(12, 15, 22, 1) 68%, rgba(12, 15, 22, 1) 100%);}
.reportbanner .banner-content {
  padding: 30px 70px 50px 0px;
 
}

.reportbanner .banner-content h1{color:#fff;    font-weight: 700;line-height: 62px;}
.reportbanner .banner-content p {color: #fff;     line-height: 33px;    }
.offer_prize {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 26px;
  color: #fff;
}
.report_pricing {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}
.offer_percentage {
  background:var(--secondary);
  border-radius: 5px;
  padding: 5px 15px;
  color: #fff;
 
  width: max-content;
  font-size: 18px;
}
 .cutoffrate {
  text-decoration: line-through;
  
}
.actual_rate {
  font-size: 28px;
  font-weight: 600;
  color: #ffddbb;
}
.reportbanner  .banner-content .banner-button { 
  display: flex; 
  flex-direction: column;
  gap: 30px;
  width: max-content;
}
.banner_big_button .btn {
  font-size: 18px;
}
.banner_big_button span{color: var(--color900); font-size: 18px;}
.reportbanner .banner-image {
  padding: 0px 0px 0px 0px;
  width: 55%;
}
.reportbanner .banner-image img{animation-name: none;}

 
.testo_name{font-size: 18px; font-weight: 700;}
 
 
.faq-wrapper .MuiTypography-root {
  font-size: 16px !important;
  line-height: 30px !important;
}

.whatweget {background-color: #f5f6f7;}
.get_card {
  background: #fcfdfe;
  padding: 25px;
  border-radius: 15px;
  height: 100%;
}
.get_avtar .testimonial_img {
  width: 75px !important;
  height: 75px !important;
  margin: inherit;
  border-color: var(--secondary);
}
.get_avtar .testimonial_img img {
  padding: 8px !important;
  filter: invert(57%) sepia(99%) saturate(1427%) hue-rotate(148deg) brightness(97%) contrast(97%);
}
.get_card .testo_name {
  font-size: 16px;
  font-weight: 600;
  
}
.get_card  .testo_message{margin-top: 10px;}

.askguruji-card {
  background: linear-gradient(279deg, rgba(51,203,240,1) 0%, rgba(26,210,255,1) 68%, rgba(7,192,237,1) 100%);
  padding: 25px;
  border-radius: 15px;
  height: 100%;
  display: flex;
}

.guru_flex {
  display: flex;
  align-items: center;
  gap: 15px;
}

.content-section h3 {
 margin-bottom: 15px;
  color: #fff;
  font-weight: 600;
}

.content-section p{
  color: #fff;
  font-weight: 600;
}

.guru-prize-section h4 {
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
  color: #fff;
}

.guru-prize-section {
  width: 250px;
  flex-shrink: 0;
}
 
.guru-prize-section .btn-primary:hover{background-color: #ffca2d; color: var(--colordark);}
.guru-prize-section .report_pricing{flex-direction: column;}
.guru-prize-section .offer_percentage{background-color: #fff; color: #000;}
.guru-prize-section .actual_rate{color: var(--colordark);}
 
 
.paymentpage-container{position: relative;}
.paymentpage-container::before{
  background-image: url(../images/light_desktop.svg);
}
.paymentpage-container::before {
  content: '';
  top: 0;
  position: absolute;
  right: 0;
  width: 30%;
  height: 100%;
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: inherit;
}
.payment_content {
  width: calc(50% + 480px);
  position: relative;
  display: table;
  height: 100%;
  min-height: 100vh;
  max-width: 1040px;
  padding-left: 36px;
  padding-bottom: 48px;
  margin: 0 auto;
}
.payment_flex {
  display: flex;
}
.payment_left_content {
  flex-grow: 1;
  padding-right: 100px;
}
.payment_form_box {
  width: 500px;
}
.payment_form {
  position: relative;
  border-radius: 2px;
  box-shadow: 6px 11px 24px 0 rgba(23, 31, 37, 0.12);
  background: #fff;
  padding: 30px 32px;
  width: 480px;
  top: 50px;
}

.form_flex {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}
.form_flex .form-label{flex-shrink: 0; margin-bottom: 0;}
.form_input {
  width: 65%;
  margin-left: auto;
}

.payment_prize {
  text-align: right;
  color: var(--colordark); font-weight: 700; font-size: 22px;
}
.payment_type  {
  text-align: right;
}
.payment_form_title{margin-bottom: 50px; position: relative;}
.payment_form_title:after{content: ''; position: absolute; width: 20%; height: 3px; background-color: var(--secondary); bottom: -10px;}
.payment_form_title h3{font-size: 18px; font-weight: 600;}

.payment_logo{margin: 70px 0px 20px 0px;}
.ql-editor p{font-weight: 400; font-size: 14px; color: var(--colordark);}
.ql-editor p strong{font-weight: 600;}
.support-details{margin-top: 30px;}
.support-details h6{font-weight: 600; color: var(--colordark); font-size: 16px;}
.sub-detail a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 6px 0px;
  color: var(--color600);
  font-size: 14px;
}
.sub-detail a svg{font-size: 18px;}
.sub-detail p , .details-footer p{font-size: 14px;}
.details-footer {margin-top: 30px; border-top:1px solid #eee; padding-top: 30px;}


.payment_status_wrapper {
  text-align: center;
}

 
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
}
.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}
.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}
.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}
.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
  content: '';
  height: 100px;
  position: absolute;
  background: var(--bodybg);
  transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}
.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}
.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, .5);
}
.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background: var(--bodybg);
}
@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
 }
  5% {
    transform: rotate(-45deg);
 }
  12% {
    transform: rotate(-405deg);
 }
  100% {
    transform: rotate(-405deg);
 }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
 }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
 }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
 }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
 }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
 }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
 }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
 }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
 }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
 }
}

.ui-success, .ui-error {
  width: 80px;
  height: 80px;
  margin: 0px auto 20px auto;
}
.ui-error-circle {
  stroke-dasharray: 260.752190248px, 260.752190248px;
  stroke-dashoffset: 260.752190248px;
  animation: ani-error-circle 1.2s linear;
}
.ui-error-line1 {
  stroke-dasharray: 54px 55px;
  stroke-dashoffset: 55px;
  stroke-linecap: round;
  animation: ani-error-line 0.15s 1.2s linear both;
}
.ui-error-line2 {
  stroke-dasharray: 54px 55px;
  stroke-dashoffset: 55px;
  stroke-linecap: round;
  animation: ani-error-line 0.2s 0.9s linear both;
}
@keyframes ani-error-line {
  to {
    stroke-dashoffset: 0;
 }
}
@keyframes ani-error-circle {
  0% {
    stroke-dasharray: 0, 260.752190248px;
    stroke-dashoffset: 0;
 }
  35% {
    stroke-dasharray: 120px, 120px;
    stroke-dashoffset: -120px;
 }
  70% {
    stroke-dasharray: 0, 260.752190248px;
    stroke-dashoffset: -260.752190248px;
 }
  100% {
    stroke-dasharray: 260.752190248px, 0;
    stroke-dashoffset: -260.752190248px;
 }
}

.payment_status_title {
  font-size: 18px;
  font-weight: 600;
 
}
.success_text{color: #4caf50;}
.unsuccess_text{color: #d90e0e;}
.payment_body {
  padding: 15px;
}

.payment_content_modal p{color: var(--color700); line-height: 26px;}

.payment_button{margin-top: 30px;}

.paymentbg{background-color: #fffaf5;}
/*campaining page css end*/



/*lifereport page css start*/
.allreportsbg{background: #fffaf5;}


.kundli_reports {   max-width: 1440px; margin:  0 auto;} 
.basic_card { border-radius: 15px;  margin-bottom: 15px;  box-shadow: var(--card_boxshadow);
  border: 1px solid var(--card_border_color);
  background-color: var(--card_bg);}
.colmargin {margin-bottom: 15px;}
.basic_head{padding: 10px 15px; border-bottom: 1px solid var(--card_border_color);}
.basic_card .basic_head h3{font-size: 18px; font-weight: 600;}
.basic_card  .web-table tbody tr td {padding: 10px 15px;}
.basic_card .web-table tbody tr td:first-child{font-weight: 600;}


.reporttabs {border-bottom: 1px solid #eee;position: sticky;top: 75px;  z-index: 999;}
.reporttabs .MuiTab-root {flex: 1 1;background-color: #fff;border-right: 1px solid #eee;text-transform: capitalize;color: var(--colordark)}
.reporttabs .MuiTab-root:last-child{border: none;}
.reporttabs .MuiTab-root.Mui-selected{background-color: #f6e1ce; color: var(--colordark) !important;}
.reporttabs .MuiTabs-indicator{background-color: #e7c6b0 !important;}
 

.astro_category_tabs {
 
  position: sticky;
  top: 125px;
  z-index: 999;
  /* background: #fff; */
}
.astro_category_tabs .MuiTabs-flexContainer { justify-content: center;}
.kundli_reports .MuiBox-root{padding: 0px !important;}

.reports_flex {
  display: flex;
 
}


.reports_aside {

  width:350px;
  flex-shrink: 0;
  transition: all .3s ease;
}
.reports_aside_fix{position: sticky; top:57px; z-index: 9;      background-color: var(--sidebarbg);
  border-radius: 0px;
  box-shadow: var(--card_boxshadow);
  border-radius: 0px;         border-right: 1px solid var(--card_border_color);     }
  .reports_aside_body {
    min-height: auto;
    max-height: calc(100vh - 60px);
    overflow: auto;
    padding-bottom: 80px;
    padding-right: 10px;

}
.report_steper {
  margin-top: 13px;
  position: relative;
}
.indication-icon svg {
  font-size: 14px;
}
.indication-icon .MuiCircularProgress-root{width: 13px !important; height: 13px !important;    color: #878787;}
.indication-icon .MuiAvatar-root{width: 22px !important; height: 22px !important;    background: #eee;}
.indication-icon .bg-success{background-color: var(--primary) !important;}
  .report_aside_cta{margin: 5px 0px; display: flex; gap: 10px; padding-left: 15px; position: relative;}
  .report_steper::after {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.12);
    left: 11px;
    top: 19px;
    z-index: -1;
    transition: all 0.5s;
}
.report_aside_cta:last-child .report_steper:after{display: none;}
.report_steper.active::after{background-color: var(--primary);}
  .report_aside_menubox {
    flex-grow: 1;
}
  .report_aside_cta .MuiList-root{     box-shadow: var(--card_boxshadow);
    background-color: var(--sidebarbg_bg);
    margin: 5px 15px 0px 15px;
    border-radius: 5px; padding: 10px;}

    .report_aside_cta .MuiList-root .MuiListItemButton-root{padding: 5px 10px 5px 15px;   border-radius: 7px;}
    .report_aside_cta .MuiList-root .MuiListItemButton-root:hover,   .report_aside_cta .MuiList-root  .MuiListItemButton-root.Mui-selected{background-color: #eee !important; color: #000 !important;}
   .report_aside_menubox .MuiListItemButton-root.Mui-selected{background-color: #eee !important; color: var(--colordark) !important;}
   .report_aside_menubox .MuiListItemButton-root{border-radius: 7px;}
   .report_aside_menubox .MuiListItemButton-root.Mui-selected .MuiTypography-root {color: var(--colordark) !important;}
.reports_rightsection{ flex-grow: 1; padding: 10px;     }
 .reports_tab_content{margin-top: 0px;}

 .report_section_card{     box-shadow: var(--card_boxshadow); border: 1px solid var(--card_border_color);     background-color: var(--card_bg);
   border-radius: 15px; padding: 25px;  margin-bottom: 15px;}
 .report_card_title{margin-bottom: 10px;}
 .report_card_title h3 {
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}
.report_card_content p {
  
  font-size: 16px;
 
  line-height: 28px;
}
.report_card_content {
  position: relative;
}

*
 
 
.report_section_card .web-table thead tr th a{color: var(--colordark);}
.report_section_card    .web-table tbody tr td{color: var(--colordark);}
.popover_flex{display: flex; width: 500px;}
.popover_pera{padding: 15px; flex-grow: 1;}
.popover_img_box{width: 180px; height: auto; flex-shrink: 0; padding: 15px; background-color: #fafafa; border-left: 1px solid var(--card_border_color);}
.popover_img{height: 100%;}
.popover_img img {width: 100%; height: 100%;object-fit: cover;}
.report_side_headtitle .MuiTypography-root{font-weight: 600 !important; font-size: 14px !important; color: var(--other_text_color);}


.reports_body_wrapper{padding: 15px;   border-radius: 15px;}
.reports_tabs {  padding: 15px 15px; margin-bottom: 15px; border-radius: 10px;    position: sticky;
  top: 73px; z-index: 9;    
  background-color:var(--card_bg);}
  .reports_tabs  .MuiTabs-root{  
    background-color: var(--card_bg);}
    .reports_tabs .MuiBox-root {background-color: transparent !important;}

.reports_tabs .MuiTabs-flexContainer {
 
  padding: 2px;
  border-radius: 5px;
  gap: 10px;
}
.reports_tabs .MuiTab-root {
  min-height: 38px;
   
  background-color:#ededed;
  color: var(--colordark);
  text-transform: capitalize;
  border-radius: 7px;
 
}
.reports_tabs .MuiTabs-indicator {
  background-color: var(--primary) !important;
  
} 
.personalize_top_head .reports_tabs{background: transparent; position: inherit; margin-bottom: 0;}
.personalize_top_head .reports_tabs .MuiTab-root {
  background: #fff;
}

.reports_tabs .MuiTab-root.Mui-selected {
  background: var(--primary);
   color: #fff;
 
  border-radius: 5px;
}
.mobile_report_head{display: none;}
.report_menu_close{display: block;  }
.report_menu_close .close{position: inherit;}
.mobmenuhead {
  padding: 8px 15px;
  border-bottom: 1px solid var(--card_border_color);
}

.dasha_wrapper {
  background: #f7ded2;
  border-radius: 15px;
  margin-bottom: 20px;
}

.dasha_headline{padding: 20px 20px;}
.dasha_wrapper .dasha_headline .report_card_title h3{font-size: 16px;color: var(--colordark) !important;   }
.remedies_wrapper .report_card_title h3{color: var(--colordark); color: var(--other_text_color) !important;  }
.dasha_head {
  display: flex;
 
  gap: 30px;
  width: 50%;
  flex-shrink: 0;
}

.dosha_head_name,.dosha_head_duration{    white-space: nowrap;     display: flex;
  flex-direction: column;  gap: 5px;   flex: 1;}

  .dosha_Prediction{display: flex; gap: 5px; flex-direction: column;}
.dosha_value_duration,.dosha_value_name,.dosha_value_Prediction{font-weight: 600;  }
.dasha-flex {
  display: flex;
  gap: 30px;
}

 .dasha_box span{color: var(--colordark);}
 .dasha_box {
  padding: 15px 20px;
  border-top: 1px solid #d6bbae;
  background: #f9efea;
  border-radius: 10px;
}
.remedies_wrapper{margin-top: 20px;    }
.ramadies_content{padding:20px 25px; border-radius: 15px; margin-left: 20px;      background-color: #eefdf3;
    border: 1px solid transparent;    border-color: #198754;  }
.ramadies_content .ramadies_title h3{    
  font-size: 16px;
  margin-bottom: 7px;
  font-weight: 600; color: #198754;}
  .ramadies_content p{font-size: 14px; line-height: 23px; color: var(--colordark); }
  .ramadies_duration   p {color: var(--colordark); }
  .ramadies_flex_box {
    display: flex;
    align-items: center;
    gap: 30px;
}


  .ramadies_flex_box .tech-icon {
    width: 100px;
    height: 100px;
    margin: 0 auto;
   background-color: #fff;
    padding: 17px;
    flex-shrink: 0;
}
.remedies_wrapper .dasha_headline{    padding: 20px 0px;}
.ramadies_description {
  flex-grow: 1;
}
/* .ramadies_flex_box .tech-icon img{  filter: invert(51%) sepia(57%) saturate(434%) hue-rotate(153deg) brightness(95%) contrast(95%);} */
.ramadies_duration {background-color: rgb(249 239 234) !important; border-color: #a43903; margin-top: 15px; }
  .ramadies_duration .ramadies_title h3{color:#a43903;}
  
/*lifereport page css emd*/


/*new chat page css start*/
.chat_action_mobile{display: none;}
 .new_chat_box {

  min-height: calc(100vh - 190px); 
  max-height: calc(100vh - 190px); 
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding: 0px 25px;
  overflow: auto;
}
.astro_chat_center_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 25px;
}
.ai_message h3 {
  font-size: 22px;
  font-weight: 600;
}

.astro_suggestions_flex {
  display: grid;
  bottom: 137px;
  gap: 10px;
  grid-template-columns: 1fr   1fr;
  justify-content: center;
  /* position: absolute; */
  z-index: 99;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 700px;
}


.suggestion_flex {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.suggestion_icon svg{color: var(--secondary);}
.chat_suggestion_body  .suggestion_flex{flex-direction: inherit; gap: 10px;}

.suggestion_content {
  line-height: 22px;
  color: var(--other_text_color);
}



.astro_suggestions_box {
  cursor: pointer;
 
  border: 1px solid var(--card_border_color);
  padding:10px 15px;
  border-radius: 10px;
  background: var(--card_bg);
  color: var(--colordark);
 
 
}
.astro_suggestions_box:hover{  background: #f5e1ce; }
.astro_suggestions_box:hover .suggestion_content, .astro_suggestions_box:hover .suggestion_icon svg{color: var(--colordark);}
.astro_sugcat_flex {
  display: flex;
  gap: 30px;
 
}
.astro_chat_category_head {
  text-align: center;
  margin-bottom: 20px;
}
.astro_chat_category_head h5 {
  font-size: 14px;
  font-weight: 500;
}




 
.astro_chat_category_flex{     display: grid;   bottom: 137px;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  /* position: absolute; */
  z-index: 99;}
  .astro_category_cta_chatbot {
    max-width: 200px;
    min-width: 200px;
}
.category_chat_icon {
  width: 30px;
  height: 30px;
}

.category_chat_icon img {
  width: 100%;
  height: 100%;
}

.category_chat_content h3 {
  font-size: 14px;
}

.astro_category_cta_chatbot a {    align-items: center;
  box-shadow: var(--card_boxshadow);
  background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  border-radius: 10px;
  padding: 10px 15px;
  display: flex;
  gap: 10px;
 
  position: relative;
  z-index: 2;}
  .astro_category_cta_chatbot a:hover,  .astro_category_cta_chatbot a.active{    background-color: #f5e1ce;  }

  [data-theme="dark"]  .astro_category_cta_chatbot a:hover ,   [data-theme="dark"]  .astro_category_cta_chatbot a.active{    background-color: #f5e1ce; color: var(--colordark);  }

  [data-theme="dark"]  .astro_category_cta_chatbot a:hover .category_chat_content h3 ,  [data-theme="dark"]  .astro_category_cta_chatbot  a.active .category_chat_content h3{color: var(--colordark);}

  .astro_suggestions_wrapper {
    max-width: 700px;
}

    .astro_suggestion_inner {
     background-color: var(--bodybg);
      border-radius: 10px;
      box-shadow: var(--card_boxshadow);
 
  border: 1px solid var(--card_border_color);
        overflow: hidden;
    }
.chat_suggestion_head {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  background: var(--card_header_bg);
}

.chat_suggestion_head a{color: var(--other_text_color);}
.astro_chat_category_head {
  text-align: center;
  margin-bottom: 20px;
}
.astro_chat_category_head h5 {
  font-size: 14px;
  font-weight: 500;
}


.chat_suggestion_head label {
   
  font-weight: 600;
  font-size: 16px;
}

.chat_suggestion_head .btn{padding: 3px 15px; font-size: 12px; display: flex; align-items: center; gap: 3px;}
.chat_suggestion_head .btn:hover{background-color: #fafafa; color: var(--colordark); border-color: var(--colordark);}
.chat_suggestion_head .btn svg{margin: 0; font-size: 12px !important; display: block;}
.chat_suggestion_body {
  padding: 15px 15px 30px 15px;
  min-height: auto;
  max-height: calc(100vh - 510px);
  overflow: auto;
}

/* .kundli_data_wrapper path {
  stroke: white;
} */
.kundli_data_wrapper{background-color: #fffddb; padding: 15px; border-radius: 15px;}
 
.kundli_data_wrapper{overflow: auto; overflow-x: scroll;}


.defaultclass .astro_suggestions_flex{    grid-template-columns:1fr;}
.defaultclass .astro_chat_category_wrapper {
  width: 200px;
}
.defaultclass .astro_chat_category_head{display: none;}
.defaultclass .astro_chat_category_inner .astro_chat_category_flex{ grid-template-columns:  1fr;}

.plan_duration_wrapper {
  padding: 5px 15px;
  background: #fff;
  text-align: center;
  background: var(--sidebarbg);
  border-bottom: 1px solid var(--card_border_color);
} 

.plan_duration_wrapper h5{font-size: 12px; }
.plan_duration_wrapper a{text-decoration: none;}

.copychat .MuiChip-root, .playpause .MuiButton-root{color: var(--other_text_color); border-color: var(--other_text_color);}
.switchlanguage .MuiSwitch-thumb{background-color: var(--secondary);}
.switchlanguage .Mui-checked .MuiSwitch-thumb {background-color: #fff;}
.related_wrapper {background: var(--card_bg);padding: 15px;  border: 1px solid var(--card_border_color); border-radius: 10px;/* margin-top: 32px; */}
.related_list{margin-top: 5px;}
.related_list .MuiTypography-root {font-size: 15px !important;color: var(--color800);}
.related_list  .MuiListItemIcon-root{    min-width: 35px;}
.sources_title{display: flex;
  align-items: center;
  gap: 5px;}
  .sourse-icon svg {
    font-size: 18px;
}
.sources_title h5 {
  font-size: 15px;
}
.related_list .MuiListItemIcon-root {
  min-width: 35px;
}

.related_list .MuiListItemIcon-root svg {
 color: var(--other_text_color);
}
/*new chat page css end*/
/*Personalized Horoscope page css start*/
.search_filter_cta Button {
  color: var(--color800);
  border-color: var(--card_border_color);
  text-transform: capitalize;
}
.horoscope_layout_inner{display: flex;
  margin-top: 2px;
  gap: 0px;}

  .horoscope-filter {
   
    width: 270px;
 flex-shrink: 0;
 
 
}
.horoscope-filter aside{      position: sticky;
  top: 59px;
  z-index: 9;
  background-color: var(--sidebarbg);
  border-radius: 0px;
  box-shadow: var(--card_boxshadow);     border-right: 1px solid var(--card_border_color);}
.aside-head {
  padding: 10px 15px;
  border-bottom: 1px solid var(--card_border_color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.aside-head  h5 {
  font-size: 16px;
}
.aside-body {
  padding: 15px;
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 107px);
}
 
 
.search-filter {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0;
  margin-bottom: 10px;
}
 
.search-astro {
  position: relative;
  flex-grow: 1;
}
.aside-body .search-astro-icon {
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 9;
}
.search-astro .searchbarastro input {
  padding-left: 45px;
}
.company_short_dec {flex-grow: 1; position: relative;}
.horoscope-aside-body {overflow: hidden;overflow-y: auto;max-height: calc(100vh - 160px);}
.filter-cta {
  padding-top: 20px;
  position: relative;
  padding-bottom: 20px;
  border-top: 1px solid var(--card_border_color);
}
.filter-cta:first-child{border: none;}
.filter-cta .MuiListItemText-primary {
  font-weight: 700;
 
  line-height: 18px;
  color: var(--other_text_color);
 
}

.filter-cta .link {
  padding: 0px 15px 0px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter-cta .MuiFormControlLabel-label {
  font-weight: 500;
 
  line-height: 18px;
 color: var(--other_text_color);
}

.horoscope-details-wrapper{flex-grow: 1;        }
 
.horoscope-details-wrapper .reports_tabs .MuiTabs-root{background-color: transparent;}
.zudic_card{     padding: 5px 10px;width: 100%; border-radius: 0px; background-color: var(--secondary);}
.zudic_card .swiper{padding-bottom: 0 !important;}
.horoscope_cta .tech-icon{       width: 75px;
  margin: 0 auto;
  height: 75px;
  padding: 17px;}

  .horoscope_cta a {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: var(--colordark);
    font-size: 14px;
    text-align: center;
    padding: 10px 7px;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: 0.3s;
    background-color: rgba(255, 255, 255, 0.8);
}
.horoscope_cta a.active,.horoscope_cta a:hover {
  border: 1px solid #84d7ea; 
  background: #d4f7ff;
 
}
.horoscope_cta a.active p {font-weight: 500;}
.horoscope_cta  p{margin-bottom: 0;}


.horoscope_wrapper{padding: 20px 20px;    }

.personalize_top_head{display: flex; flex-direction: column; gap: 20px;  background-color: var(--homebannerbg);  padding:20px 20px; margin-bottom: 15px;    border-bottom: 1px solid var(--card_border_color);
    }

 
 
  .personalize_heading {
    position: relative;
    text-align: center;
}
.search_filter_cta {
  position: absolute;
  top: 0;
  right: 0;
}
.personalize_heading h3 {
 
  font-size: 26px;
  font-weight: 600;
}
 .horoscope_match,.horoscope_ratting{flex: 1;}
  .horoscope_match_flex {
    display: grid;
    padding: 10px 15px;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 35px;
  }
  .my_horoscope_match {
    flex: 1;
}
  .horoscope_match_cta {
    flex-direction: column;
    display: flex;
    text-align: center;
    gap: 10px;
  }
  
  .horoscope_match_cta  label{
    font-weight: 500;
    font-size: 14px;
    color: var(--other_text_color);
  }
  
  .rating_card {
    border-radius: 15px;
    background-color: var(--card_bg); border: 1px solid var(--card_border_color);
    height: 100%;
   
  }
  
  .ratings_cta label {
    font-weight: 500;
    font-size: 14px;
    color: var(--other_text_color);

 
  }
  
  .rating_title {
    padding: 15px;
    text-align: center;
     
  }
  .rating_title h3 {
    font-size: 16px;
    font-weight: 600;
  }
  .all_ratings {
    padding: 10px 15px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    row-gap: 13px;
  }
  .ratings_cta {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2px;
   
   
  }
  .all_ratings .MuiRating-root {
    margin: 0 auto; font-size: 16px;
}
.horoscope_sign_zudic .tech-icon{     height: 108px;
  width: 108px;
  margin: 0 auto;

  padding: 24px;}
  .my_horoscope_match .all_ratings{display: flex; justify-content: center; gap: 15px;}

 
 .my_horoscope_flex{display: flex; gap: 15px; width: 100%; margin: 0 auto;}
.MuiRating-icon svg{ color: #faaf00;}
/*Personalized Horoscope page css end*/



/*dashboard page css start*/
.welcome_title {
  margin-bottom: 30px;
}
.welcome_title h2{font-size: 40px;  }
.dashboard_top{    border-bottom: 1px solid var(--card_border_color);  border-bottom-left-radius:15px;  border-bottom-right-radius:15px;  background: var(--homebannerbg);   padding: 130px 0px 100px 0px;     }
.dashboard_top .horoscope_match,  .dashboard_top  .horoscope_ratting{flex: inherit;}
.dashboard_top .all_ratings{column-gap: 15px;}
  .dashboard_top .rating_card {
    
    background-color: var(--card_bg);
    border-color: var(--card_border_color);
    
  }

.dashboard_top  .rating_title {
 
  border-bottom: 1px solid var(--card_border_color);
}
.dashboard_zudic_detail h4{margin-bottom: 10px;}
 .dashboard_zudic_detail h4 , .dashboard_zudic_detail p{color: var(--other_text_color);}
 .dashboard_zudic_detail p{font-size: 16px; line-height: 30px;}
 .dashboard_zudic_detail .my_horoscope_flex{width: 100%; margin-top: 25px;}
 /* .dashboard_zudic_detail .horoscope_match_cta label,.dashboard_zudic_detail .ratings_cta label{color: #fff;} */
 .dashboard_plan_box{padding-left: 40px; width: 100%; height: 100%;}
 .dashboard_plan_details{margin-left: 20px; background-color:var(--card_bg);  border: 1px solid var(--card_border_color); width: 100%; border-radius: 15px; position: relative;}
 .dashboard_plan_heading {
  padding:30px 15px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dashboard_plan_heading h3{font-size: 18px;}
.dashboard_plan_button{   padding: 0px 15px;}
.dashboard_plan_button h4{font-size: 16px; text-align: center; margin-bottom: 15px;}
.dashboard_plan_body{padding: 15px;}
.trail_box {
  background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  box-shadow: var(--card_boxshadow);
border-radius: 10px;
  width: 100%;
  
  padding: 10px 15px;
}

.trail_box h4{font-size: 14px;    line-height: 25px;}
.plan_feature_list{padding: 15px 0px;}
.plan_feature_list h4{font-size: 16px; color: var(--secondary);    font-weight: 600;}
.plan_feature_list ul li {
  list-style: disc;
  padding: 5px 0px;
 
}

.plan_feature_list ul   {
 padding-left: 25px;
}
.plan_feature_list ul li  h5{font-size: 14px; line-height: 26px;}
.dashboard_features {
  background-color: var(--card_bg);
  border: 1px solid var(--card_border_color);
  box-shadow: var(--card_boxshadow);
  border-radius: 15px;
  padding: 50px 30px;
  height: 100%;
}
.dash_feature_icon{width: 200px; height: 200px; flex-shrink: 0;}
.dashboard_features_flex {
  display: flex;
  align-items: center;
 
}
.dashboard_features_content{padding-left: 20px;}
.dashboard_features_content h2 {font-size: 24px;    font-weight: 600; line-height: 32px; margin-bottom: 15px;   color: var(--primary);}

  [data-theme="dark"] .dashboard_features_content h2{background: linear-gradient(to right, #f56b30 25%, #def5ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;}

.dash_chat_prompt{margin-top: 30px; position: relative;}  
.dash_chat_field {
  display: flex;
  align-items: center;
  gap: 15px;
}
.dash_chat_field .access_message{    height: 67px;}
/* .dash_chat_field  input{background-color: #283342;} */
.dashsendprompt{flex-shrink: 0;}
.dashsendprompt svg{color:#fff;}
 .commingsoon .our_features_flex{     flex-direction: column; gap: 40px;}
 .commingsoon .our_features_box{height: 100%;    padding: 20px 15px;}
 .commingsoon .feature_content h2{font-size: 24px; text-align: center; line-height: 32px;}
 .commingsoon .feature_content{width: 100%; text-align: center;}
 .commingsoon  .feature_image_box{width: 300px; height: 300px;}
 .commingsoon .feature_image_box img{position: inherit;}

 .g_signup_body   .step-successbox {
  padding: 25px 15px;
}
