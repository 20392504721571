/*  
:root {
    --primary:#f16122;
    --secondary:#439bc2;
    --bodybg:rgb(22, 28, 36);
    --body_text_color:#F9FAFB;
    --other_text_color:#fff;
    
    --heading_color:rgb(255, 255, 255);
    --card_bg:#161c24;
    --card_hover_bg:#1e2732;
    --card_border_opacity: 1;
    --card_border_color:rgb(82 82 81 / var(--card_border_opacity));
    --card_boxshadow:rgba(0, 0, 0, 0.4) -40px 40px 80px;
    --text_light:#C4CDD5;
    --text_muted:#DFE3E8; 
    --color200:#F4F6F8;
    --color300:#DFE3E8;
    --color400:#C4CDD5;
    --color500:#919EAB;
    --color600:#637381;
    --color700:#454F5B;
    --color800:#212B36;
    --color900:#161C24;
    
    }
     */


    
     :root {
      --primary:#f16122;
      --secondary:#439bc2;
      --bodybg:#fff;
      --homebannerbg:#fff4f4;
      --body_text_color:#212B36;
      --other_text_color:#212B36; 
      --heading_color:#212B36;
      --card_bg:#fafafa;
      --card_hover_bg:#eee;
      --card_border_opacity: 1;
      --card_border_color:#d8d8d8;
      --card_boxshadow:rgba(50, 50, 93, 0.024) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px; 
      --sidebarbg:#fff; 
      --sidebarbg_bg: #fafafa;
      --text_muted:#637381; 
      --colordark:#212B36; 
      --footerbg:#fafafa;
      --bottomfooterbg:#eee;
    --report_card_content:#212B36;
    --repomainbg:#f3f5f8;
    --loginbg:#f3f5f8;
    --personalize_top_header:#fff4f4;
    --inputbg:#fff;
    --dashboard_banner_card:#fff;
    --dashboard_banner_card_border:#e5e5e5;
    --scroll_track:#f1f1f1;
    --scroll_thumb:#d3d3d3;
      }     
    
        
          [data-theme="dark"]  {
              --primary:#f16122;
              --secondary:#439bc2;
              --bodybg:#141314;
              --body_text_color:#e6e1e3;
              --other_text_color:#e6e1e3; 
              --heading_color:rgb(255, 255, 255);
              --text_muted:#DFE3E8; 
              --colordark:#212B36; 
              --card_bg:#1c1b1d;
              --card_hover_bg:#45455a; 
              --card_border_opacity: 1;
              --card_border_color:rgb(51 49 51 / var(--card_border_opacity));
              --card_boxshadow:rgba(0, 0, 0, 0.4) -40px 40px 80px; 
              --sidebarbg:#211f21; 
              --sidebarbg_bg: #2e2c2e;
              --card_header_bg:#211f21; 
              --bottomfooterbg:#211f21;
               --homebannerbg:#141314; 
              
           
             
              --report_card_content:#e6e1e3;
              --repomainbg:#161c24;
              --loginbg:#000; 
              --personalize_top_header:#20252e;
              --inputbg:#211f21;
              --dashboard_banner_card:#1e1e1e;
              --dashboard_banner_card_border:#444;
              --scroll_track:#fff;
              --scroll_thumb:#ff7955;
        }      
          
          @media (max-width: 1024px) {
          
           
           
          }
          
          @media (max-width: 992px) {
              header.fixed-header{box-shadow: none;}
              .mini-sidebar .sidebar {
                  width: 60px;
              }
              .main-content{ margin-left: 0px !important;}
           
           
          }
          
          @media (max-width: 768px) {
              header {
                  padding: 10px 0;
              }
              .navbar-brand {
                  align-items: center;
                  display: flex;
                  gap: 10px;
                  margin-right: 0;
              }
              .closemenubar, .menubar {
                  display: block;
              }
              .mobilemenu {
                 display: none;
                  left: 0;
                  width: 95%;
                  box-shadow: var(--card_boxshadow);
                  box-shadow: 0px 1px 6px rgba(0, 0, 0, .22);
                  background-color: var(--sidebarbg);
                  opacity: 1;
                  margin: 0 auto;
                  right: 0;
                  top: 65px;
                  border-radius: 14px;
                  height: auto;
                  position: fixed;
                  z-index: 99999;
                  border: 1px solid var(--card_border_color);
                  
              }
              .menubar a{transition: 0.3;}
              .menubar a:hover{color: var(--primary);}
  
              .menuclose{display: none;}
              .sidebarmobilemenu .menuopen{display: none;}
              .sidebarmobilemenu  .menuclose{display: block;}
             .sidebarmobilemenu  .mobilemenu{display: block;}
              .mobilemenu ul {
                  display: inherit;
                padding: 15px 0px;
              }
          
              .mobilemenu ul li {
                  margin: 0;
                  padding: 0;
              }
              .inner-header .navbar-menu ul li {
                  padding: 0px 0px;
              }
              .mobilemenu ul li a {
                  display: block;
                  font-size: 14px;
                  margin: 0;
                  padding: 10px 15px;
                  border-bottom: 1px solid var(--card_border_color);
                  
              
              }
              .mobilemenu ul li:last-child a{border: none;}
              .mobilemenu ul li a.active {
                  background-color: #f9f9f9;
                  color: var(--colordark);
              }
              .logo {
                  width: 135px;
              }
              
           
          
          
              .banner-content-box {
                  flex-direction: column;
              }
              .banner-image {
                  padding: 70px 0px 0px 0px;
                  width: 100%;
              }
          
              .banner-content {
                  order: 2;
                  padding: 30px 0px;
                  text-align: center;
                  width: 100%;
              }
          
              .banner-content h1 {
                  font-size: 23px;
                  line-height: 35px;
                  margin-bottom: 15px;
              }
          
              .banner-content p {
                  font-size: 16px;
                  line-height: 27px;
              }
          
              .paddinglr{padding: 0px 15px;}
            
              .astro_category_flex{grid-template-columns: 1fr 1fr;}
              .section-pedding{padding: 50px 0px;}
              .technology-listing{grid-template-columns: 1fr  1fr 1fr;}
              .dot-overlay, .technology-cta::before{display: none;}
              .technology-cta a {
                  padding: 15px 0px;}
          
          
                  .footer-logo img {
                      height: 20px !important;
                  }
                  .bottom-footer-box {
                      padding: 0;
                  }
                  .footer-all-links {
                      -moz-column-gap: 15px;
                      column-gap: 15px;
                      grid-template-columns: repeat(auto-fill, minmax(48%, 1fr));
                      padding-left: 0;
                      row-gap: 26px;
                  }
                  .footer-all-links {
                      grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
                      row-gap: 15px;
                  }
                  .footer-menu-head h5 {
                      font-size: 14px;
                      font-weight: 600;
                  }
                  .footer-menubox ul {
                      padding: 5px 0;
                  }
                  .footer-menubox ul li a {
                      font-size: 12px;
                  }
                  .bottom-footer-box {
                      flex-direction: column;
                      gap: 10px;
                  }
                  .terms-link ul li {
                      font-size: 12px;
                      line-height: 22px;
                      text-align: center;
                  }
                  .terms-link ul li a {
                      font-size: 12px;
                  }
          .category_icon{    width: 80px;
              height: 80px;     padding: 13px;     margin: 15px auto 0px auto;}
          
             .tech-icon{    width: 80px;
                  height: 80px;     padding: 15px;      }
                  .technology-cta p{font-size: 14px;}
              .category_content {
                  
                  padding: 15px 0px 20px 0px;
              }
              .dot-overlay span:nth-child(3),  .dot-overlay span:nth-child(4),.dot-overlay span:nth-child(5){display: none;}
              .category_content h3 {
                  font-size: 14px;
              }
              .step-heading h2 {
                  font-weight: 700;
                  font-size: 22px;
              }
              .step-heading p {
                  margin-bottom: 20px;
                  line-height: 23px;
                  font-size: 14px;
                  padding: 0px;
              }
              footer {
                 
                  padding: 15px 15px;
              }
          
              .closemenubar {
                  width: 30px;
                  height: 30px;
                  border-radius: 50px;
                  background-color: #eee;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: auto;
                  color: var(--colordark);
                  position: absolute;
                  top: 10px;
                  right: 15px;
                  z-index: 99;
              }
              .navbar-menu ul li .main-menu::after{display: none;}
     
              .navbar-button .btn{font-size: 14px;}
              .navbar-button{gap:5px;}
              .menubar a svg{font-size: 30px;}
              .user-profile-head a{padding: 0;}
            
              .personalize_top_head{padding: 15px 15px;}
              .horoscope-details-wrapper .reports_tabs {
                 
                top: 92px;
                
                padding: 15px 0px !important;
            }
            .report_menu_close .close {
              position: inherit;
              margin-right: 15px;
              margin-top: 0px;
              background-color: transparent;
              color: var(--other_text_color);
              font-size: 24px;
            }
              .reports_body_wrapper {
                  padding: 15px 0px;
                  border-radius: 5px;
                 
              }
              .report_card_content p {text-align: left;
                  line-height: 26px;   font-size: 13px;}
                  .ramadies_flex_box .tech-icon{margin: inherit;}
              .report_section_card{margin: 0px 10px 15px 10px;padding: 20px 15px;}
              .reports_rightsection{padding: 5px;}
               .reports_tabs{          padding: 5px 15px;     top: 100px;}
               .mobile_report_head{display: block;         position: sticky;
                  top: 62px;
                  z-index: 9;}
               .mobile_report_head .btn{border-radius: 0;    font-size: 12px;     padding: 5px 15px;}
               .reports_flex{flex-direction: column;}
               .reports_aside_fix {
                  position: fixed;
                  width: 0;
                 top: 100px; left: -250px; }
          
                 .reports_aside.showreportmenu  .reports_aside_fix{width: 90%;  left: 0; top: 0;  transition: all .3s ease;        top: 53px;}
          
                  .report_menu_close{display: flex;         margin-left: auto;}
          
                  .reports_aside_body {
                      
                      max-height: calc(100vh - 100px);
                      padding-bottom: 50px;
                    
                  }
                  .regenerate {
                      display: none;
                  }
          
                  .reports_tabs .MuiTab-root{padding: 0px 15px;    font-size: 14px;
                      font-weight: 500;}
          
                      .mobmenuhead{display: flex; align-items: center; gap: 15px;   }
          
                      .match_flex{flex-direction: column;}
                      .match_making_result_left{width: 100%;}
                    .matchmaking_right_body  .MuiBox-root{    padding: 15px 0px;}
                    .aiproctoring-cta{flex-direction: column; gap: 30px;    padding: 25px 15px; }
                    .proctor-content {
                      text-align: center;
                  }
             
                    .padding_medium {
                      padding: 20px 0px;
                  }
                  .minibanner{ padding: 20px 0px;}
                    .proctor-icon{
                    margin:inherit;
                    width: 130px;
                    height: 130px;
                  }
                  .proctor-box{padding: 10px 0px;}
                  .centerbordered {
                      display: none;
                  }
                  .minibanner-content h2 {
                      font-size: 24px;}
                      .matchmaking-banner .minibanner-content{width: 100%;}
                      .minibanner-content p {
                          font-size: 14px;
                          line-height: 23px;
                          
                      }
                    .aiproctoring-flex{flex-direction: column;gap: 0px; }
                    .match_form_flex{flex-direction: column; gap: 15px;}
                    .match_formbox{width: 100%;}
                    .card_table .web-table thead tr th, .card_table .web-table tbody tr td{    white-space: nowrap;}
                    .match_result_box{min-height: auto; max-height: inherit; overflow: inherit;}
                    .width45 .match_form_box{width: 100%;}
          
                    .dasha-flex,.dasha_head{flex-direction: column;        gap: 15px;}
                    .dosha_head_name, .dosha_head_duration{gap: 1px;}
                    .dasha_headline{padding: 20px 15px;}
                    .dasha_box {
                      padding: 15px 15px;}
                      .ramadies_content{margin-left: 0;     padding: 25px 15px;}
                      .ramadies_flex_box{flex-direction: column; align-items: inherit;gap: 20px;}
                      .ramadies_description {
                          text-align: left;
                      }
                      .report_card_title{text-align: left;}
                      .ramadies_content .ramadies_title h3{text-align: left;}
                      .reports_aside_fix{  z-index: 99;}
          
                      .horoscope-filter {
                         
                          position: fixed;
                          width: 0;
                          top: 100px;
                          left: -250px;
                      }
          .horoscope-filter.showreportmenu{left: 0;        
              z-index: 99;        width: 300px;
              top: 53px;}
                      .my_horoscope_flex{flex-direction: column;}
                      .personalize_heading h3 {
                          font-size: 16px;}
                          .our_features_box{padding: 20px 15px;}
                          .our_features_flex{flex-direction: column-reverse; gap: 30px;}
                          .feature_image,.feature_image_box{width: 100%; height: auto !important;}
                          .feature_content{width: 100%;   }
                          .textleft_imagetight .feature_content{padding-right: 0;}
                          .imagetight_textleft .feature_content{padding-left: 0;}
                          .feature_button .btn{width: 100% !important;}
                          .feature_image_box img{position: inherit;}
                          .feature_content h2{font-size: 22px; line-height: 31px; margin-bottom: 7px;}
                          .relationshiptool_home{height: auto !important;}
                          .feature_content p {
                              font-size: 14px;
                              line-height: 27px;
                          }
                          .plan_feature_list ul {
                              padding-left: 15px;
                          }
                          .horoscope_wrapper {
                              padding: 0px 0px;
                          }
                          .reports_tabs{box-shadow: none; top: 98px;}
                          .dashboard_plan_box{padding-left: 0; margin-top: 20px;}
                          .dashboard_plan_details{margin-left: 0;}
                          .dashboard_top{    padding: 90px 0px 50px 0px;}
                          .welcome_title h2 {
                              font-size: 28px; 
                              line-height: 40px;
                          }
                          .welcome_title{margin-bottom: 15px;}
                          .dashboard_zudic_detail p {
                              font-size: 14px;
                              line-height: 26px;
                          }
                          .dashboard_zudic_detail h4{font-size: 16px;}
                          .dashboard_plan_button {
                              position: inherit;
                              left: inherit;
                              right: inherit;
                              padding: 0px 15px;}
                              .dashboard_features_flex{flex-direction: column;}
                              .dashboard_features{padding: 20px 15px;}
                              .dashboard_features_content{padding: 0;}
                              .dash_feature_icon{    width: 200px !important;
                                  height: 200px !important;
                                  flex-shrink: 0;        padding: 35px !important;}
                                  .dashboard_features_content h2,.commingsoon .feature_content h2 {
                                      font-size: 22px;}
                                      .commingsoon .feature_content h2 br{display: none;}
                                      .aside-body{    max-height: calc(100vh - 100px);}
          
                                      .asrtro_aside_flex{max-height: calc(100vh - 60px);}
                                      .history_head{display: flex; align-items: center; gap: 15px;    padding: 20px 10px; }
                                      .asrtro_aside_flex .report_menu_close   {    position: absolute;
                                          right: -70px;
                                          top: 18px;}
                                      .asrtro_aside{position: fixed; width: 0; left: -500px; opacity: 0; background-color: var(--card_bg); z-index: 9999; height: 100%; }
                                      .asrtro_aside.showhistorymobile{  width: 300px; left: 0px; opacity: 1; }
          .asrtro_chat_chart_body .report_menu_close{position: absolute;left: 10px;top: 13px;}
          
          
          
          
                                      .sidebar-history{margin-top: 0;}
                                      .asrtro_chat_chart_box{position: fixed; width: 0; left: -500px; opacity: 0; background-color: var(--card_bg); z-index: 999; height: 100%;}
                                      .asrtro_chat_chart_box.showchartmobile{  width: 80%; left: auto; right:0; opacity: 1; }
                                      .chat_avtar_history{display: none;}
                                      .astro_chat_center_content{gap: 10px;}
                                      .ai_message h3 {
                                          font-size: 15px; text-align: center; line-height: 24px;}
                                          .bottom_chat_wrapper{position: fixed; left: 0;}
                                          .chat-flex{flex-direction: column;}
                                          .answer_content ul, .answer_content ol {
                                              padding-left: 10px;
                                              margin-bottom: 15px;
                                          }
                                          .defaultclass .astro_chat_category_wrapper{width: auto;}
                                          .astro_chat_category_flex {
                                              display: flex;
                                              flex-wrap: wrap;}
                                              .astro_category_cta_chatbot{flex: 1;     max-width: inherit;
                                                  min-width: auto;}
                                                  .astro_chatbot_wrapper{height: auto;}
                                                  .defaultclass .astro_chat_category_head{display: block;}
                                          .left_action, .right_action{flex-wrap: wrap;}
                                          .astro_sugcat_flex{flex-direction: column;}
                                          .astro_suggestions_wrapper {        max-width: 100%;
                                              position: absolute;
                                    
                                              z-index: 99;
                                              width: 96%;
                                              left: 0;
                                              right: 0;
                                              top: 15px;
                                              margin: 0 auto;
          
                                          }
                                          .chat_suggestion_body{max-height: calc(100vh - 270px);}
                                          .astro_chat_center_content    .astro_suggestions_flex{overflow: auto;}
                                          .astro_suggestions_flex{        
                                              width: 340px;
                                              justify-content: inherit;
                                              padding-bottom: 10px;}
                                              .astro_suggestions_box{white-space: nowrap;}
                                              .new_chat_box{padding: 0px 15px;}
          
                                              .defaultclass .astro_suggestions_box{white-space: inherit;}
                                              .defaultclass .astro_suggestions_flex{display: grid;}
                                              .astro_chat_category_head h5{line-height: 26px;}
                                              .chat_form{    padding: 15px 10px;}
                                              .login_left{display: none;}
                                              .auth-content{padding: 0px;}
                                              .chat_action_mobile{             position: fixed;
                                                  bottom: 95px;
                                                  right: 7px;
                                                  display: block;
                                                  z-index: 99;}
                                              .chat_action_mobile .MuiSpeedDial-fab { width: 40px; height: 40px; background-color: var(--primary);        line-height: 0;}
                                              .chat_action_mobile .MuiSpeedDial-fab:hover{background-color: var(--primary);}
                                                  .chat_action_mobile .MuiFab-root .MuiSpeedDialIcon-icon{font-size: 25px !important;        margin: 0;}
                                               
                                                  .chat_action_mobile .MuiSpeedDialAction-staticTooltipLabel{width: max-content; font-size: 14px;}
                                                  .asrtro_chat_right{position: relative;}
                                                  .plan-tables-container{flex-direction: column;}
                                                  .terms-content{padding: 15px;}
                                                  .accesspersonalize .dashboard_plan_button {
                                                      top: 0;
                                                  }
      
                                                  .my_horoscope_flex{width: 100%;}
  
                                                  .horoscope_sign_zudic .tech-icon {
                                                      height: 65px;
                                                      width: 65px;
                                                      margin: 0 auto;
                                                      padding: 15px;
                                                  }
  
                                                  .dashboard_top .horoscope_sign_zudic .tech-icon {
                                                      height:100px;
                                                      width: 100px;
                                                      padding: 20px;
                                                  }
  
                                                  .g_signup_step_button .btn {
                                                      flex: 1;
                                                  }
  
                                                  .chat_responcebox{padding-left: 0;}
                                                  .chat_message{    padding: 30px 15px;}
                                                  .answer_actions{    padding: 0px 0px;}
  
                                                  .dashboard_top .all_ratings{gap: 10px;}
                                                  .my_horoscope_match .all_ratings{gap: 10px;}
                                                  .horoscope_match_cta label{font-size: 12px;}
                                                  
                                                
          }
          
          @media (max-width: 767px) {
             
                          
          } 
          
          
          
          
          
          @media (max-width: 360px) {
           
          }
          
          
          @media only screen and (min-width: 320px) and (max-width: 575px) {
           
          }